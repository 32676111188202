import { ethers } from 'ethers';
import PledgeStatus from 'contracts/pledgeVault/constants';
import { AllocationModel } from './constants';

const generateLevelsActive = (minAccessLevel) => {
  const levelsActive = Array(5).fill(false);

  if (minAccessLevel >= 0 && minAccessLevel < 5) {
    for (let i = minAccessLevel; i < 5; i++) {
      levelsActive[i] = true;
    }
  }

  return levelsActive;
};

function buildPhaseConfig(phase) {
  return {
    name: phase.phaseName,
    startTimestamp: Math.floor(new Date(phase.startDate).getTime() / 1000),
    endTimestamp: Math.floor(new Date(phase.endDate).getTime() / 1000),
    levelsActive: generateLevelsActive(phase.minAccessLevel),
    allocationModel: AllocationModel[phase.model],
    phaseCap: phase.cap ? ethers.utils.parseUnits(phase.cap, 'mwei') : 0,
    walletCapsForLevels: phase.levels.map((cap) =>
      cap ? ethers.utils.parseUnits(cap, 'mwei') : 0
    ),
    allowlistMerkleRoot: phase.allowlistMerkleRoot || ethers.constants.HashZero,
    nftPrice: phase.nftPrice ? ethers.utils.parseUnits(phase.nftPrice, 'mwei') : 0,
  };
}

const preparePhasesForDeploy = (phases) => {
  return phases.reduce(
    (acc, phase) => {
      if (!phase.phaseName) {
        throw new Error('Phase name is required');
      }

      if (phase.startDate > phase.endDate) {
        throw new Error('Phase start date cannot be greater than end date');
      }

      const phaseConfig = buildPhaseConfig(phase);

      return {
        phaseIds: [...acc.phaseIds, phase.index],
        phaseConfigs: [...acc.phaseConfigs, phaseConfig],
      };
    },
    { phaseIds: [], phaseConfigs: [] }
  );
};

const prepareConfigForDeploy = ({ minContribution, maxContribution, dealSize }) => {
  if (!minContribution) {
    throw new Error('Min contribution is required');
  }

  if (!dealSize) {
    throw new Error('Deal size is required');
  }

  return {
    dealSize: ethers.utils.parseUnits(dealSize, 'mwei'),
    minContribution: ethers.utils.parseUnits(minContribution, 'mwei'),
    maxContribution: ethers.utils.parseUnits(maxContribution || '0', 'mwei'),
  };
};

function mapPhases(phases) {
  return phases.map(
    ({
      minAccessLevel,
      minViewLevel,
      model,
      name,
      startTimestamp,
      endTimestamp,
      whitelist,
      cap,
      levelCaps,
      index,
      proRataCirculatingSupply,
      nftPrice,
    }) => ({
      minAccessLevel,
      minViewLevel,
      model,
      phaseName: name,
      startDate: new Date(startTimestamp.replace(/Z$/, '')),
      endDate: new Date(endTimestamp.replace(/Z$/, '')),
      expanded: false,
      cap,
      index,
      nftPrice,
      levels: levelCaps,
      proRataCirculatingSupply,
      whitelist: whitelist?.map(({ amount, wallet }) => ({
        address: wallet,
        personalCap: amount,
      })),
    })
  );
}

function isValidPledgeStatus(deal, pool) {
  if (!deal.useOldPledge && pool?.status !== PledgeStatus.Freezed) {
    return false;
  }

  return true;
}

function isValidPoolConfig(poolConfig) {
  return poolConfig.minContribution && poolConfig.dealSize;
}

export {
  mapPhases,
  isValidPledgeStatus,
  isValidPoolConfig,
  generateLevelsActive,
  buildPhaseConfig,
  preparePhasesForDeploy,
  prepareConfigForDeploy,
};
