import React from 'react';
import NumberInput from 'components/input/number-input';

const BdtLevelCapInput = ({ phaseLevels, setPhaseLevels, disabled, errors }) => {
  const handleChange = (bdtLevel, cap) => {
    setPhaseLevels(phaseLevels.map((level, i) => (bdtLevel === i ? cap : level)));
  };

  return (
    <div className="bdt-level-container ">
      <label>
        Setup levels <span style={{ fontSize: 8 }}>(optional)</span>
      </label>
      <div className="deal-edit-modal__content levels-form-content">
        {phaseLevels.map((level, index) => (
          <NumberInput
            key={index}
            label={`Level ${index}`}
            value={level}
            onChange={(e) => handleChange(index, e.target.value)}
            decimalNumber="2"
            disabled={disabled}
            error={errors?.includes(index) ? 'Level cap must be less than deal size' : ''}
          />
        ))}
      </div>
    </div>
  );
};

export default BdtLevelCapInput;
