import { queryCalls } from 'contracts/multicallContract';
import { getDealStatusName } from 'models/dealStatusModel';
import { groupByChain, isFieldMulticallSupported } from 'models/common/helpers';
import { getDealInfo, getMyContributionAmount } from 'contracts/poolContract';
import { getActivePhases } from 'services/apiService';
import { mergeClaimers, prepareClaimers } from './claimers';
import { parseClaimerModels, isClaimable } from '../dealModel';
import { getProviderByChainId } from '../../contracts/providers';

export async function enrichWithMetadata(rawDeals, accountInfo) {
  return Promise.all(
    rawDeals.map(async (rawDeal) => {
      const { deal, ...preFetchedData } = rawDeal;

      return getDealMetadata({
        dealApi: deal,
        accountInfo,
        preFetchedData,
      });
    })
  );
}

export const getDealMetadata = async ({ dealApi, accountInfo, preFetchedData = {} }) => {
  const { address: walletAddress } = accountInfo;

  if (!dealApi.address) {
    return dealApi;
  }

  const dealAddress = dealApi.address;
  const provider = getProviderByChainId(dealApi.chainId);

  const deal = {
    ...dealApi,
    ...parseClaimerModels(dealApi.claimers, dealAddress, walletAddress),
    status: getDealStatusName(dealApi.statusId),
    id: dealApi.id.toString(),
  };

  deal.contributedAmount = await getContributedAmount({
    dealAddress: deal.address,
    preFetchedData,
    walletAddress,
    provider,
  });

  if (isClaimable(deal)) {
    return handleClaimableDeal({ deal, walletAddress, provider, preFetchedData });
  }

  const dealChainData = await getDealChainInfo({
    dealAddress: deal.address,
    preFetchedData,
    provider,
  });

  const { phases } = await getActivePhases(deal.id);

  return {
    ...deal,
    ...dealChainData,
    activePhases: phases,
  };
};

const handleClaimableDeal = async ({ deal, walletAddress, preFetchedData }) => {
  const claimers = prepareClaimers(deal, walletAddress);

  const multicallCalls = [];

  const claimerAmountCalls = claimers.flatMap(({ claimableAmountCalls }) =>
    claimableAmountCalls.map((call, index) => ({
      call,
      index: multicallCalls.length + index,
      chainId: call.chainId,
    }))
  );

  multicallCalls.push(...claimerAmountCalls);

  const claimersByChain = groupByChain(multicallCalls);

  const multicallResults = await queryCallsByChain(claimersByChain, multicallCalls);

  const mergedClaimers = await mergeClaimers({
    claimers,
    walletAddress,
    contributedAmount: deal.contributedAmount,
    multicallResults,
  });

  return {
    ...deal,
    claimers: mergedClaimers,
    claimAmount: preFetchedData.claimAmount,
  };
};

export async function queryCallsByChain(claimersByChain, multicallCalls) {
  const resultsMap = new Map();

  // eslint-disable-next-line no-restricted-syntax
  for await (const [chainId, callsWithIndex] of Object.entries(claimersByChain)) {
    const chainResultMap = new Map();

    const provider = getProviderByChainId(chainId);

    const calls = callsWithIndex.map(({ call }) => call);

    const results = await queryCalls(provider, calls);

    // reorganize results back into their original index
    results.forEach((result, idx) => {
      const originalIndex = callsWithIndex[idx].index;

      chainResultMap.set(originalIndex, result);
    });

    resultsMap.set(+chainId, chainResultMap);
  }

  return multicallCalls.map((call, i) => {
    return resultsMap.get(call.chainId).get(i % 4);
  });
}

export async function handleContributions(deal, accountInfo, prefetchedPersonalCap) {
  const { userAccessLevel, relockMessage } = accountInfo;

  if (
    (relockMessage && relockMessage.length && userAccessLevel === -1) ||
    deal.raisedAmount === deal.dealSize ||
    (deal.minAccessLevel === 4 && !deal.userWhitelisted) ||
    (deal.minAccessLevel !== 4 && deal.minAccessLevel > userAccessLevel)
  ) {
    return '0';
  }

  if (
    parseFloat(prefetchedPersonalCap) === 0 &&
    deal.allocationModel === 'Personal Cap' &&
    parseFloat(deal.userCap) <= parseFloat(deal.contributedAmount)
  ) {
    return '0';
  }

  return prefetchedPersonalCap;
}

async function getContributedAmount({ preFetchedData, provider, dealAddress, walletAddress }) {
  if (isFieldMulticallSupported(preFetchedData.contributedAmount)) {
    return preFetchedData.contributedAmount || 0;
  }

  return getMyContributionAmount(provider, dealAddress, walletAddress);
}

async function getDealChainInfo({ preFetchedData, provider, dealAddress }) {
  if (isFieldMulticallSupported(preFetchedData.dealChainData)) {
    return preFetchedData.dealChainData;
  }

  return getDealInfo(dealAddress, provider);
}
