import { getAllDeals } from 'services/apiService';
import { useQuery, useQueryClient } from 'react-query';
import { getDealModels } from 'models/user';

const useUserDealsQuery = (authState, { onSuccess, onError, enabled }) => {
  const queryClient = useQueryClient();

  const { data: deals, isFetching } = useQuery(
    ['user-deals'],
    async () => {
      const userDealsApi = await getAllDeals();

      const deals = await getDealModels(authState, userDealsApi);

      deals.forEach((deal) => {
        queryClient.setQueryData(['deal', deal.id], deal);

        if (deal.claimAmount) {
          queryClient.setQueryData(['my-withdraw', deal.address], deal.claimAmount);
        }
      });

      return deals;
    },
    {
      onSuccess,
      onError,
      enabled,
    }
  );

  return {
    deals,
    isLoading: isFetching,
  };
};

export default useUserDealsQuery;
