import { ethers } from 'ethers';
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import IconButton from 'components/button/icon-button';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import CustomProgressBar from 'components/progress-bar/custom-progress-bar';
import CustomSlider from 'components/progress-bar/custom-slider';
import NumberInput from 'components/input/number-input';
import {
  setSharedNotification,
  updateGlobal,
  setActiveHashes,
  addNotification,
  updateAuth,
} from 'store/actions';
import notificationTypes from 'constants/notificationTypes';
import { addChain } from 'contracts/browserWallet';
import { getAllowance } from 'contracts/erc20';
import { arbitrumProvider } from 'contracts/providers';
import {
  TRANSFER_PROXY_ADDRESS,
  USDT_ADDRESS,
  ARBITRUM_CHAIN_ID,
  ARBITRUM_CHAIN_NAME,
} from 'constants/config';
import {
  addStringAmounts,
  roundNumberToDecimals,
  subStringAmounts,
  getDealStatusClass,
  capitalize,
} from 'utils/helpers';

import './index.scss';
import useContributePool from 'contracts/poolManager/hooks/useContributePool';
import usePoolQuery from 'contracts/pledgeVault/hooks/usePoolQuery';
import CustomSelect from 'components/select/custom-select';
import useERC20Allowance from 'contracts/erc20/useERC20Allowance';
import useErc20Approve from 'contracts/erc20/useERC20Approve';
import { useQueryClient } from 'react-query';
import useRemainingAllocationsQuery from 'contracts/poolManager/hooks/useRemainingAllocationsQuery';
import { buildAllowlistData } from './helpers';

const DealEditRow = ({ deal }) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const globalReducer = useSelector((state) => state.global);
  const authReducer = useSelector((state) => state.auth);
  const [errorMessage, setErrorMessage] = useState('');

  const { chainId, activeHashes, eligiblePhases } = globalReducer;
  const { accountInfo } = authReducer;

  const { allowance } = useERC20Allowance(deal.address);

  const { remainingAllocations } = useRemainingAllocationsQuery(deal, {
    phaseIds: eligiblePhases.map((phase) => phase.index),
    allowListDatas: eligiblePhases.map((phase) => buildAllowlistData(phase)),
  });

  const { approve, isLoading: isApproving } = useErc20Approve(USDT_ADDRESS, {
    onSuccess: () => {
      queryClient.invalidateQueries(['pool-info', deal.address]);
      queryClient.invalidateQueries(['allowance', deal.address]);
    },
  });

  const [selectedPhase, setSelectedPhase] = useState({
    id: eligiblePhases[0].id,
    data: eligiblePhases[0],
  });

  const { contribute } = useContributePool(deal.address, {
    onSuccess: () => {
      queryClient.invalidateQueries(['pool-info', deal.address]);
      queryClient.invalidateQueries(['allowance', deal.address]);
      queryClient.invalidateQueries(['remaining-allocations', deal.address, accountInfo.address]);
    },
  });

  const { pool } = usePoolQuery(deal);

  const maxAllocation = +selectedPhase.data.cap || +pool?.dealSize;

  const [contributionValue, setContributionValue] = useState(pool?.minContribution || 0);

  const getErrorMessage = () => {
    if (!pool) return '';

    if (errorMessage === 'min') return `Min. = ${+pool.minContribution} USDT`;
    if (errorMessage === 'max') {
      const levelCap =
        selectedPhase.data.levelCaps.find((cap) => +cap > 0) ||
        selectedPhase.data.levelCaps[accountInfo.userAccessLevel];

      return `Max. = ${Number(levelCap)
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} USDT`;
    }

    if (errorMessage === 'personalCap')
      return `Unfilled deal balance must be at least ${pool.minContribution} or equal to 0.`;
    if (errorMessage === 'allowance') return 'Approve additional allowance';
    if (errorMessage === 'unfilled')
      return `Contribution must be max ${maxAllocation - +pool.totalContribution}.`;
    if (errorMessage === 'whitelist')
      return `Contribution must be max ${ethers.utils.formatUnits(
        selectedPhase.data.allowlistAmount,
        'mwei'
      )}.`;
    if (errorMessage === 'max-contribution')
      return `Contribution must be max ${pool.maxContribution}.`;
    if (errorMessage === 'deal-filled')
      return `The deal is filled. Contributions are no longer allowed.`;
    if (errorMessage === 'remaining-allocation')
      return `Remaining allocation is ${remainingAllocations?.[selectedPhase.data.index]}.`;
    if (errorMessage === 'nftPrice') return 'Contribution must be a multiple of the NFT price.';
    return '';
  };

  const onChangeContributionValue = (e) => {
    const { value } = e.target;
    setContributionValue(value);
  };

  const minAmountLeftCondition = useCallback(() => {
    if (!pool) return;

    const raisedAfterContribution = +pool.totalContribution + +contributionValue || 0;

    const unfilledAmount = maxAllocation - raisedAfterContribution;

    return unfilledAmount >= +pool.minContribution || unfilledAmount <= 1;
  }, [pool, contributionValue, maxAllocation]);

  const sliderMaxValue = () => {
    const sumAmount = addStringAmounts(deal.personalCap, deal.contributedAmount, 6);
    const rounded = roundNumberToDecimals(sumAmount, 6);

    return maxAllocation - Number(rounded);
  };

  const sliderCurrentValue = () => {
    const sumAmount = addStringAmounts(deal.contributedAmount, contributionValue || '0', 6);
    return roundNumberToDecimals(sumAmount, 6).toString();
  };

  const checkMinContribution = useCallback(() => {
    if (Number(contributionValue) < Number(pool?.minContribution)) {
      setErrorMessage('min');
      return true;
    }

    return false;
  }, [contributionValue, pool]);

  const checkAllowlistAmount = useCallback(() => {
    const allowlistAmount = +ethers.utils.formatUnits(selectedPhase.data.allowlistAmount, 'mwei');

    return (
      selectedPhase.data.allowlistProof?.length > 0 &&
      allowlistAmount > 0 &&
      Number(contributionValue) > allowlistAmount
    );
  }, [contributionValue, selectedPhase]);

  const checkMaxContribution = useCallback(() => {
    const exceedsLevelCap =
      (!selectedPhase.data.allowlistAmount || selectedPhase.data.allowlistAmount === '0') &&
      selectedPhase.data.levelCaps.find((cap) => +cap > 0) &&
      Number(contributionValue) > +selectedPhase.data.levelCaps[accountInfo.userAccessLevel];

    if (selectedPhase.data.allowlistAmount) {
      const exceedsWhitelistAmount = checkAllowlistAmount();

      if (exceedsWhitelistAmount) {
        setErrorMessage('whitelist');
        return true;
      }
    }

    const isDealFilled = +pool.totalContribution === +pool.dealSize;

    if (isDealFilled) {
      setErrorMessage('deal-filled');
      return true;
    }

    const exceedsMaxContribution =
      pool.maxContribution > 0 && +pool.maxContribution < (+contributionValue || 0);

    if (exceedsMaxContribution) {
      setErrorMessage('max-contribution');
      return true;
    }

    const exceedsRemainingAllocation =
      (remainingAllocations?.length > 0 &&
        remainingAllocations?.[selectedPhase.data.index] < +contributionValue) ||
      0;

    if (exceedsRemainingAllocation) {
      setErrorMessage('remaining-allocation');
      return true;
    }

    const raisedAfterContribution = +pool.totalContribution + +contributionValue || 0;
    const unfilledAmount = maxAllocation - raisedAfterContribution;

    const exceedsUnfilledAmount = unfilledAmount < 0;

    if (exceedsUnfilledAmount) {
      setErrorMessage('unfilled');
      return true;
    }

    if (exceedsLevelCap) {
      setErrorMessage('max');
      return true;
    }

    return false;
  }, [contributionValue, selectedPhase, accountInfo.userAccessLevel, pool]);

  const checkPersonalCap = useCallback(() => {
    if (!minAmountLeftCondition()) {
      setErrorMessage('personalCap');
      return true;
    }
    return false;
  }, [minAmountLeftCondition]);

  const checkAllowance = useCallback(() => {
    if (Number(allowance) < Number(contributionValue)) {
      setErrorMessage('allowance');
      return true;
    }
    return false;
  }, [allowance, contributionValue]);

  const checkNftPrice = useCallback(() => {
    if (!selectedPhase.data.nftPrice) {
      return;
    }

    const remainder = Number(contributionValue) % Number(selectedPhase.data.nftPrice);

    if (remainder !== 0) {
      setErrorMessage('nftPrice');
      return true;
    }

    return false;
  }, [selectedPhase.data.nftPrice, contributionValue]);

  useEffect(() => {
    if (
      !checkMinContribution() &&
      !checkMaxContribution() &&
      !checkPersonalCap() &&
      !checkAllowance() &&
      !checkNftPrice()
    ) {
      setErrorMessage('');
    }
  }, [checkMinContribution, checkMaxContribution, checkPersonalCap, checkAllowance, checkNftPrice]);

  const onChangeContributionSlider = (event, val) => {
    if (val < Number(deal.contributedAmount)) {
      setContributionValue('0');
      return;
    }
    const contrValue = subStringAmounts(val.toString(), deal.contributedAmount, 6);
    setContributionValue(contrValue);
  };

  const onCloseDealModal = () => {
    dispatch(updateGlobal({ activeDeal: null }));
    setContributionValue('');
  };

  const showErrorNotification = (message) => {
    dispatch(
      setSharedNotification({
        status: 'error',
        title: 'Error',
        description: message,
      })
    );
  };

  const handleApprove = async () => {
    const networkChecked = await addChain(chainId, ARBITRUM_CHAIN_ID);
    if (networkChecked) {
      if (+contributionValue === 0) {
        return;
      }

      setErrorMessage('');

      const tx = await approve({
        spender: deal.address,
        amountInWei: ethers.utils.parseUnits(contributionValue.toString(), 6),
      });

      if (tx) {
        dispatch(
          setActiveHashes([
            ...activeHashes,
            {
              hash: tx.transactionHash,
              pending: false,
              chain: ARBITRUM_CHAIN_NAME,

              callback: async () => {
                const usdtAllowance = await getAllowance(
                  arbitrumProvider,
                  USDT_ADDRESS,
                  accountInfo.address,
                  TRANSFER_PROXY_ADDRESS,
                  6
                );

                dispatch(
                  updateAuth({
                    accountInfo: {
                      ...accountInfo,
                      usdtAllowance,
                    },
                  })
                );
              },
            },
          ])
        );
        dispatch(
          addNotification({
            name: tx.transactionHash,
            chain: ARBITRUM_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.LOCKUP,
          })
        );
      } else {
        showErrorNotification('Something went wrong. Please try again.');
      }
    } else {
      showErrorNotification('You need to change your network to "Ethereum Mainnet" to continue.');
    }
  };

  const callContribute = async () => {
    if (!minAmountLeftCondition()) {
      showErrorNotification(
        `Unfilled deal balance must be at least ${pool.minContribution} or equal to 0.`
      );
      return;
    }

    const phaseToContribute = selectedPhase.data;

    const allowListData = buildAllowlistData(phaseToContribute);

    contribute({
      phaseId: phaseToContribute.index,
      amount: ethers.utils.parseUnits(contributionValue, 6),
      allowListData,
    });
  };

  return (
    <div className="deal-holder d-flex full-width">
      <div className="deal-row-top">
        <div className="deal__field deal__field-avatar vertical-center">
          <RoundedAvatar src={deal.imageUrl} />
        </div>
        <div className="deal__field deal__field-name vertical-center">
          <div>
            <span>{deal.name}</span>
            <CustomProgressBar total={maxAllocation} value={Number(pool?.totalContribution)} />
          </div>
        </div>
        <div
          className={`deal__field deal__field-status--${getDealStatusClass(
            deal.status
          )} vertical-center`}
        >
          <span className="deal__field-status__icon">
            <SvgIcon name="dot" />
          </span>
          <span className="deal__field-status__name">
            {deal.status === 'opened' ? 'live' : capitalize(deal.status)}
          </span>
        </div>
        <div className="deal__field vertical-center">
          <CustomSlider
            value={sliderCurrentValue()}
            min={0}
            max={sliderMaxValue()}
            onChange={onChangeContributionSlider}
          />
        </div>
        <div className="deal__field deal__field-modal-contribution vertical-center">
          <span className="number-input-holder">
            <NumberInput
              placeholder="0.0"
              value={contributionValue}
              onChange={onChangeContributionValue}
              error={getErrorMessage()}
              decimalNumber="6"
            />
            <div className="max">
              <IconButton
                icon="iconBridgeMax"
                onClick={() =>
                  setContributionValue(
                    +deal.personalCap > +accountInfo.usdtBalance
                      ? accountInfo.usdtBalance
                      : deal.personalCap
                  )
                }
              />
            </div>
          </span>
          <span>USDT</span>
        </div>
        <div className="active-phase-select">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              minWidth: '200px',
            }}
          >
            <span
              style={{
                marginBottom: '2px',
                fontSize: '10px',
                color: '#808080',
              }}
            >
              Select Phase
            </span>
            <CustomSelect
              value={selectedPhase.id}
              items={eligiblePhases.map((phase) => ({
                name: phase.name,
                value: phase.id,
              }))}
              onSelect={(_, phaseId) =>
                setSelectedPhase({
                  id: phaseId,
                  data: eligiblePhases.find((p) => p.id === phaseId),
                })
              }
            />
          </div>
        </div>
        <div className="deal__field deal__field-modal-action vertical-center">
          <RoundedButton onClick={onCloseDealModal}>Cancel</RoundedButton>
          <RoundedButton
            type="secondary"
            disabled={
              Number(contributionValue) <= Number(allowance) ||
              isApproving ||
              errorMessage !== 'allowance'
            }
            onClick={handleApprove}
          >
            Approve
          </RoundedButton>
          <RoundedButton
            type="primary"
            disabled={
              Number(contributionValue) < Number(pool?.minContribution) ||
              Number(contributionValue) > Number(deal.personalCap) ||
              Number(accountInfo.usdtBalance) < Number(contributionValue) ||
              Number(allowance) < Number(contributionValue) ||
              !minAmountLeftCondition() ||
              errorMessage ||
              isApproving
            }
            onClick={callContribute}
          >
            <div className="d-flex">Contribute</div>
          </RoundedButton>
        </div>
      </div>

      <div className="deal-info-mobile show">
        <div className="deal-info-mobile-actions">
          <RoundedButton onClick={onCloseDealModal}>Cancel</RoundedButton>
          <RoundedButton
            type="secondary"
            onClick={handleApprove}
            disabled={
              Number(contributionValue) <= Number(allowance) ||
              isApproving ||
              errorMessage !== 'allowance'
            }
          >
            Approve
          </RoundedButton>
          <RoundedButton
            type="primary"
            disabled={
              Number(contributionValue) < Number(pool?.minContribution) ||
              Number(contributionValue) > Number(deal.personalCap) ||
              Number(accountInfo.usdtBalance) < Number(contributionValue) ||
              Number(allowance) < Number(contributionValue) ||
              !minAmountLeftCondition() ||
              errorMessage ||
              isApproving
            }
            onClick={callContribute}
          >
            <div className="d-flex">Contribute</div>
          </RoundedButton>
        </div>
      </div>
    </div>
  );
};

DealEditRow.propTypes = {
  deal: PropTypes.shape(),
};

DealEditRow.defaultProps = {
  deal: {},
};

export default React.memo(DealEditRow);
