import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import usePledgePoolQuery from 'contracts/pledgeVault/hooks/usePledgePoolQuery';
import usePoolQuery from 'contracts/pledgeVault/hooks/usePoolQuery';
import { InitialPhaseState } from '../constants';
import { isValidPledgeStatus, mapPhases } from '../helpers';
import { validateCaps, validatePhases } from '../components/validators';

const DeployContractContext = createContext();

export const useDeployContractContext = () => useContext(DeployContractContext);

export const DeployContractProvider = ({ deal, children }) => {
  const [whitelistPickerData, setWhitelistPickerData] = useState(null);
  const [phases, setPhases] = useState([InitialPhaseState]);
  const [phaseIdsToUpdate, setPhaseIdsToUpdate] = useState([]);
  const [poolConfig, setPoolConfig] = useState({
    totalContribution: '',
    minContribution: '',
    maxContribution: '',
    dealSize: '',
  });

  const [errors, setErrors] = useState({});

  const { pool, isLoading: isLoadingPool } = usePoolQuery(deal);

  const { pool: pledgePool, isLoading: isLoadingPledgePool } = usePledgePoolQuery(deal.address);

  useEffect(() => {
    if (!pool?.dealSize || isLoadingPool) {
      return;
    }

    setPoolConfig({
      dealSize: pool.dealSize,
      minContribution: pool.minContribution,
      totalContribution: pool.totalContribution,
      maxContribution: +pool.maxContribution || '',
    });
  }, [pool, isLoadingPool]);

  useEffect(() => {
    if (!deal.phases || !deal.phases.length) return;

    setPhaseIdsToUpdate([]);
    setPhases(() => mapPhases(deal.phases));
  }, [deal.phases]);

  const toggleExpand = (index) => {
    setPhases((prev) =>
      prev.map((phase, i) => (index === i ? { ...phase, expanded: !phase.expanded } : phase))
    );
  };

  const handlePhaseChange = (name, value, index) => {
    let { expanded } = phases[index];

    // 4 -> whitelist
    if (['Unlimited', 'WalletCap', '4'].includes(value)) {
      expanded = true;
    }

    if (['cap', 'levels', 'whitelist'].includes(name)) {
      validateCaps(name, value, { index, poolConfig, errors, setErrors });
    }

    setPhaseIdsToUpdate((prev) => {
      if (deal.phases.find((p) => p.index === phases[index].index)) {
        return [...prev, phases[index].index];
      }

      return prev;
    });

    setPhases((prev) =>
      prev.map((phase, i) => (index === i ? { ...phase, [name]: value, expanded } : phase))
    );
  };

  const arePhasesValid = useMemo(() => {
    return validatePhases(phases);
  }, [phases]);

  return (
    <DeployContractContext.Provider
      value={{
        deal,
        pool,
        phases,
        setPhases,
        errors,
        setErrors,
        pledgePool,
        poolConfig,
        setPoolConfig,
        toggleExpand,
        arePhasesValid,
        phaseIdsToUpdate,
        handlePhaseChange,
        whitelistPickerData,
        setWhitelistPickerData,
        isLoadingPool: isLoadingPool || isLoadingPledgePool,
        isValidPledgeStatus: isValidPledgeStatus(deal, pledgePool),
        hasErrors: Object.values(errors).some((error) => !!error),
      }}
    >
      {children}
    </DeployContractContext.Provider>
  );
};
