import React from 'react';
import SvgIcon from 'components/svgIcon';
import './index.scss';
import RoundedButton from 'components/button/rounded-button';

import InfoTooltip from 'components/tooltip/custom/InfoTooltip';
import usePledgeBalanceQuery from 'contracts/pledgeVault/hooks/usePledgeBalanceQuery';
import useWithdrawMutation from 'contracts/pledgeVault/hooks/useWithdrawMutation';

export default function PledgeWithdraw() {
  const { balance, isLoading } = usePledgeBalanceQuery();

  const { withdraw } = useWithdrawMutation();

  return (
    <div className="withdraw-balance-cards">
      <div className="balance-container">
        <div className="balance-content">
          <SvgIcon width={48} name="iconLogoGreen" />
          <div className="balance-amount">
            <span>Unpledged balance</span>
            <span>{isLoading ? 'Loading...' : balance?.formatted || 0}</span>
          </div>
        </div>
        <div className="withdraw-button">
          <RoundedButton type="secondary" disabled={!+balance?.formatted} onClick={withdraw}>
            Withdraw
          </RoundedButton>
          <InfoTooltip title="Withdraw all unpledged balance" placement="bottom-end" />
        </div>
      </div>
    </div>
  );
}
