import { useQuery } from 'react-query';
import usePoolManager from 'contracts/poolManager/hooks/usePoolManager';
import { useNotifications } from 'hooks';
import { ethers } from 'ethers';

const useMaxCloseAmountQuery = (userAddress) => {
  const [, showErrorNotification] = useNotifications();

  const poolManager = usePoolManager();

  const { data: maxCloseAmount, isLoading: isRoleConfigLoading } = useQuery(
    ['role-config', userAddress],
    async () => {
      const roleDefinition = await poolManager.getRoleDefinitionForAccount(userAddress);

      const maxClosingAmountBN = roleDefinition.maxClosingAmount;

      return +ethers.utils.formatUnits(maxClosingAmountBN, 'mwei');
    },
    {
      onError: () => showErrorNotification('Failed to fetch role config'),
      enabled: !!userAddress,
    }
  );

  return {
    maxCloseAmount,
    isLoading: isRoleConfigLoading,
  };
};

export default useMaxCloseAmountQuery;
