const PoolConfigValidationFields = {
  minContribution: 'minContribution',
  maxContribution: 'maxContribution',
  dealSize: 'dealSize',
};

const PoolConfigDependencies = {
  minContribution: ['maxContribution', 'dealSize'],
  maxContribution: ['minContribution', 'dealSize'],
  dealSize: ['minContribution', 'maxContribution'],
};

const errorMessages = {
  minContribution: {
    greaterThanMax: 'Min contribution must be less than max contribution',
    greaterThanDealSize: 'Min contribution must be less than deal size',
  },
  maxContribution: {
    lessThanMin: 'Max contribution must be greater than min contribution',
    greaterThanDealSize: 'Max contribution must be less than deal size',
  },
  dealSize: {
    lessThanContribution: 'Deal size must be greater than total contribution',
    lessThanMin: 'Deal size must be greater than min contribution',
    lessThanMax: 'Deal size must be less than max contribution',
  },
};

const validatePoolConfigField = (field, value, args, visitedFields = new Set()) => {
  const { poolConfig, setErrors } = args;

  const validationRules = {
    [PoolConfigValidationFields.minContribution]: [
      {
        condition: () => !!poolConfig.maxContribution && +value > +poolConfig.maxContribution,
        message: errorMessages.minContribution.greaterThanMax,
      },
      {
        condition: () => +value > +poolConfig.dealSize,
        message: errorMessages.minContribution.greaterThanDealSize,
      },
    ],
    [PoolConfigValidationFields.maxContribution]: [
      {
        condition: () => !!value && +value < +poolConfig.minContribution,
        message: errorMessages.maxContribution.lessThanMin,
      },
      {
        condition: () => +value > +poolConfig.dealSize,
        message: errorMessages.maxContribution.greaterThanDealSize,
      },
    ],
    [PoolConfigValidationFields.dealSize]: [
      {
        condition: () => +value < +poolConfig.totalContribution,
        message: errorMessages.dealSize.lessThanContribution,
      },
      {
        condition: () => +value < +poolConfig.minContribution,
        message: errorMessages.dealSize.lessThanMin,
      },
      {
        condition: () => +value < +poolConfig.maxContribution,
        message: errorMessages.dealSize.lessThanMax,
      },
    ],
  };

  const rules = validationRules[field] || [];
  const error = rules.find((rule) => rule.condition())?.message || null;

  setErrors((prev) => ({
    ...prev,
    [field]: error,
  }));

  validateDependencies(visitedFields, field, poolConfig, setErrors);

  return error;
};

function validateDependencies(visitedFields, field, poolConfig, setErrors) {
  if (!visitedFields.has(field)) {
    visitedFields.add(field);

    PoolConfigDependencies[field].forEach((dependency) => {
      validatePoolConfigField(
        dependency,
        poolConfig[dependency],
        {
          setErrors,
          poolConfig,
        },
        visitedFields
      );
    });
  }
}

const validatePhasesCaps = (phases, args) => {
  const possibleCapTypes = ['cap', 'levels', 'whitelist'];

  phases.forEach((phase, index) => {
    possibleCapTypes.forEach((capType) => {
      validateCaps(capType, phase[capType], { index, ...args });
    });
  });
};

const validateCaps = (name, value, args) => {
  const { index, poolConfig, errors, setErrors } = args;

  const validateCap = () => {
    if (+value > +poolConfig.dealSize) {
      setErrors({ ...errors, [`${index}-cap`]: 'Cap must be less than deal size' });
      return;
    }

    setErrors({ ...errors, [`${index}-cap`]: '' });
  };

  const validateLevels = () => {
    const levels = [];

    value.forEach((level, index) => {
      if (+level > +poolConfig.dealSize) {
        levels.push(index);
      }
    });

    setErrors({ ...errors, [`${index}-levels`]: levels.length > 0 ? levels : '' });
  };

  const validateWhitelist = () => {
    const whitelist = value.filter((item) => +item.personalCap > +poolConfig.dealSize);

    setErrors({ ...errors, [`${index}-whitelist`]: whitelist.length > 0 ? whitelist : '' });
  };

  switch (name) {
    case 'cap':
      validateCap();
      break;
    case 'levels': {
      validateLevels();
      break;
    }
    case 'whitelist': {
      validateWhitelist();
      break;
    }
    default:
      break;
  }
};

const validatePhases = (phases) => {
  const invalidPhase = phases.find((phase) => phase.startDate >= phase.endDate || !phase.phaseName);

  const arePhasesValid = !invalidPhase;

  return arePhasesValid;
};

export { validatePoolConfigField, validatePhasesCaps, validateCaps, validatePhases };
