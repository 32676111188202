import { useNotifications } from 'hooks';
import { useMutation } from 'react-query';
import { insertPhaseMerkleRoots } from 'services/apiService';
import { MutationArgs } from 'utils/types';

function usePhaseMerkleRoot(dealId, { onSuccess, onError } = MutationArgs) {
  const [, showErrorNotification] = useNotifications();

  const { mutateAsync: insertMerkleRoots, isLoading } = useMutation(
    (data) => insertPhaseMerkleRoots(data, dealId),
    {
      onSuccess,
      onError: () => {
        showErrorNotification('Error while inserting merkle roots');

        if (onError) onError();
      },
    }
  );

  const checkedInsertMerkleRoots = ({ phases, poolConfig }) => {
    const arePhaseDatesValid = phases.every((phase) => {
      const { startDate, endDate } = phase;

      return new Date(startDate) < new Date(endDate);
    });

    if (!arePhaseDatesValid) {
      showErrorNotification('One or more phases have invalid start or end dates');
      return;
    }

    insertMerkleRoots({ phases, poolConfig });
  };

  return { insertMerkleRoots: checkedInsertMerkleRoots, isLoading };
}

export default usePhaseMerkleRoot;
