import PoolStatus from 'contracts/poolManager/constants';
import useFinalizePledgeAndConfigPool from 'contracts/poolManager/hooks/useFinalizePledge';
import useUpdatePoolStatus from 'contracts/poolManager/hooks/useUpdatePoolStatus';
import { useNotifications } from 'hooks';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';
import { useQueryClient } from 'react-query';

function useDealLivePromotion(deal) {
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const queryClient = useQueryClient();

  const onError = () => {
    showErrorNotification('Something went wrong while promoting deal');
  };

  const onSuccess = () => {
    queryClient.invalidateQueries(['deal', deal.id]);
    queryClient.invalidateQueries(['admin-deal', deal.id]);

    showSuccessNotification('Deal promoted successfully');
  };

  const { finalizePledgeAndConfigPool, isLoading: isFinalizingPledge } =
    useFinalizePledgeAndConfigPool(deal.address, {
      onSuccess,
      onError,
    });

  const { updatePoolStatus, isLoading: isUpdatingPoolStatus } = useUpdatePoolStatus(deal.address, {
    onSuccess,
    onError,
  });

  const promoteDeal = async () => {
    if (!deal.useOldPledge) {
      await finalizePledgeAndConfigPool();
      return;
    }

    await updatePoolStatus(PoolStatus.Active);
  };

  const checkedPromoteDeal = useWithCorrectNetwork(promoteDeal);

  return {
    promoteDeal: checkedPromoteDeal,
    isLoading: isFinalizingPledge || isUpdatingPoolStatus,
  };
}

export default useDealLivePromotion;
