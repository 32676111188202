import * as calls from './builders';
import { CALL_TYPE } from './constants';

export default function buildCall(type) {
  switch (type) {
    case CALL_TYPE.DEAL_INFO:
      return calls.buildDealInfoCall;
    case CALL_TYPE.CLAIM_AMOUNT:
      return calls.buildClaimAmountCall;
    case CALL_TYPE.CLAIM_AMOUNT_OLD:
      return calls.buildOldClaimAmountCall;
    case CALL_TYPE.ADDRESS_CONTRIBUTION_AMOUNT:
      return calls.buildAddressContributionAmountCall;
    case CALL_TYPE.MAX_CONTRIBUTION_AMOUNT:
      return calls.buildMaxContributionAmountCall;
    case CALL_TYPE.TOKEN_INFO:
      return calls.buildTokenInfoCall;
    default:
      throw new Error('Invalid call type');
  }
}
