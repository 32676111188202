import { useQuery } from 'react-query';
import { getAdminDealData } from 'services/apiService';

export default function useAdminDealData(id) {
  const { data, isFetching } = useQuery(['deal-admin-data', id], () => getAdminDealData(id));

  return {
    data,
    isLoading: isFetching,
  };
}
