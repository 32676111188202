import React, { useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import IconButton from 'components/button/icon-button';
import { useNotifications } from 'hooks';
import { useQueryClient } from 'react-query';
import remote from './remote';

const initialFormData = {
  round: '',
  tokenPrice: '',
  tokensSold: '',
  vesting: '',
  amount: '',
};

const RaiseStatsModal = ({ open, onClose, deal, raiseStats }) => {
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const nextRowId = useRef(
    raiseStats && raiseStats.length > 0 ? raiseStats[raiseStats.length - 1].sortField : 1
  );

  const [data, setData] = useState(raiseStats);
  const updateStats = remote.useUpdateDealRaiseStats(deal.id);

  const onChangeData = (e, rowId) => {
    const { name, value } = e.target;
    const foundItem = data.find((row) => row.id === rowId);
    const updatedItem = { ...foundItem, [name]: value };
    setData(data.map((item) => (item.id === rowId ? updatedItem : item)));
  };

  const addRow = () => {
    setData([...data, { id: nextRowId.current, dealId: deal.id, ...initialFormData }]);
    nextRowId.current += 1;
  };

  const removeRow = (rowId) => {
    setData(data.filter((row) => row.id !== rowId));
  };

  const checkIfDbRow = (rowId) => !!raiseStats.find((stat) => stat.id === rowId);

  const checkIfEmptyNewRow = (rowId) => {
    if (checkIfDbRow(rowId)) return false;

    const memoryRow = data.find((row) => row.id === rowId);
    const memoryRowCopy = { ...memoryRow };
    delete memoryRowCopy.id;
    delete memoryRowCopy.dealId;
    if (Object.values(memoryRowCopy).some(Boolean)) return false;

    return true;
  };

  const queryClient = useQueryClient();

  const onConfirm = async (e) => {
    e.preventDefault();
    try {
      const filteredData = data.filter((row) => !checkIfEmptyNewRow(row.id));
      const deletedStatRows = raiseStats.filter(
        (stat) => !data.find((item) => item.id === stat.id)
      );
      const deletePromises = deletedStatRows.map((stat) =>
        updateStats.mutateAsync({
          id: stat.id,
          data: { ...initialFormData },
        })
      );

      const updatePromises = filteredData.map((row, index) =>
        updateStats.mutateAsync({
          id: checkIfDbRow(row.id) ? row.id : 0,
          data: { ...row, sortField: index + 1 },
        })
      );

      await Promise.all([...deletePromises, ...updatePromises]);

      queryClient.invalidateQueries(['admin-deal', deal.id]);

      showSuccessNotification(`Updated successfully`);
      onClose();
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  return (
    <Dialog open={open} className="deal-info-modal raise-stats-modal" onClose={onClose}>
      <div className="modal-header">
        <div className="header-title">
          <SvgIcon name="iconDealsModalEdit" />
          <h3>Raise Stats</h3>
        </div>
        <div className="header-actions">
          <RoundedButton type="transparent" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" buttonType="submit" form="raise-stats-form">
            <div className="d-flex">Publish</div>
          </RoundedButton>
        </div>
      </div>
      <form className="modal-body" id="raise-stats-form" onSubmit={onConfirm}>
        <div className="form-header">
          <p>Stats List</p>
          <div className="form-header-actions">
            <IconButton icon="plus" onClick={addRow} />
            <p>Add new</p>
          </div>
        </div>
        <div className="form-labels">
          <div className="form-col">
            <p>Round</p>
          </div>
          <div className="form-col">
            <p>Token Price</p>
          </div>
          <div className="form-col">
            <p>Tokens for Sale</p>
          </div>
          <div className="form-col">
            <p>Vesting</p>
          </div>
          <div className="form-col">
            <p>Amount</p>
          </div>
          <div className="form-col remove-col" />
        </div>
        <div className="form-table-holder">
          {data.map((row) => (
            <div className="form-table-row" key={row.id}>
              <div className="form-col">
                <input
                  type="text"
                  name="round"
                  value={row.round}
                  onChange={(e) => onChangeData(e, row.id)}
                />
              </div>
              <div className="form-col">
                <input
                  type="text"
                  name="tokenPrice"
                  value={row.tokenPrice}
                  onChange={(e) => onChangeData(e, row.id)}
                />
              </div>
              <div className="form-col">
                <input
                  type="text"
                  name="tokensSold"
                  value={row.tokensSold}
                  onChange={(e) => onChangeData(e, row.id)}
                />
              </div>
              <div className="form-col">
                <input
                  type="text"
                  name="vesting"
                  value={row.vesting}
                  onChange={(e) => onChangeData(e, row.id)}
                />
              </div>
              <div className="form-col">
                <input
                  type="text"
                  name="amount"
                  value={row.amount}
                  onChange={(e) => onChangeData(e, row.id)}
                />
              </div>
              <div className="form-col remove-col" onClick={() => removeRow(row.id)}>
                <SvgIcon name="iconDeleteX" />
              </div>
            </div>
          ))}
        </div>
      </form>
    </Dialog>
  );
};

export default RaiseStatsModal;
