import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import SortableTable from 'components/sortable-table';
import { renderSafeValue } from 'utils';
import useContributionTableColumns from './hooks/useContributionTableColumns';
import useContributionsQuery from './hooks/useContributionsQuery';

const UserContributionsTable = ({ address }) => {
  const { data: contributions, isLoading } = useContributionsQuery(address);

  const columns = useContributionTableColumns();

  if (isLoading) return null;

  if (contributions.length === 0)
    return <div className="no-data-found">No contributions found</div>;

  return (
    <SortableTable tableHead={columns} rows={contributions} type="deal-info">
      {(sortedRows) =>
        sortedRows.map((row) => (
          <TableRow key={row.id}>
            {columns.map(({ column, accessor }) => (
              <TableCell key={column}>{renderSafeValue(accessor(row))}</TableCell>
            ))}
          </TableRow>
        ))
      }
    </SortableTable>
  );
};

export default UserContributionsTable;
