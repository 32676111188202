import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';
import EntityEditField from 'features/deals/EntityEditField';
import { useQueryClient } from 'react-query';
import remote from './remote';

const initialState = [
  ['', ''],
  ['', ''],
  ['', ''],
  ['', ''],
  ['', ''],
];

const TeamModal = ({ open, onClose, deal, teamAdvisors }) => {
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const [data, setData] = useState(initialState);
  const updateAdvisors = remote.useUpdateDealTeamAdvisors(deal.id);

  const queryClient = useQueryClient();

  const onConfirm = async (e) => {
    e.preventDefault();
    try {
      const filteredData = data.filter((advisor) => advisor.some(Boolean));

      const promises = filteredData.map((advisor) => {
        return updateAdvisors.mutateAsync({
          id: advisor[2] || 0,
          data: { logo: advisor[0], website: advisor[1], sortField: advisor[3] },
        });
      });
      await Promise.all(promises);

      queryClient.invalidateQueries(['admin-deal', deal.id]);

      showSuccessNotification(`Updated successfully`);
      onClose();
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  useEffect(() => {
    if (teamAdvisors && teamAdvisors.length > 0) {
      const dataToSet = initialState.map((item, index) => {
        const found = teamAdvisors.find((pi) => pi.sortField === index + 1);
        if (found) {
          return [found.logo || '', found.website || '', found.id, found.sortField];
        }
        return item;
      });
      setData(dataToSet);
    }
  }, [teamAdvisors]);

  const onUploadUrlChange = (itemIndex, newValue) => {
    setData((prev) =>
      prev.map((item, i) =>
        i === itemIndex ? [newValue, item[1], item[2], itemIndex + 1] : [...item]
      )
    );
  };

  const onWebsiteUrlChange = (itemIndex, newValue) => {
    setData((prev) =>
      prev.map((item, i) =>
        i === itemIndex ? [item[0], newValue, item[2], itemIndex + 1] : [...item]
      )
    );
  };

  return (
    <Dialog open={open} className="deal-info-modal team-modal" onClose={onClose}>
      <div className="modal-header">
        <div className="header-title">
          <SvgIcon name="iconDealsModalEdit" />
          <h3>Team &amp; Advisors</h3>
        </div>
        <div className="header-actions">
          <RoundedButton type="transparent" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" onClick={onConfirm}>
            <div className="d-flex">Publish</div>
          </RoundedButton>
        </div>
      </div>
      <form className="modal-body" id="team-form" onSubmit={onConfirm}>
        {data?.map((item, i) => (
          <div className="col" key={i}>
            <EntityEditField
              uploadUrl={item[0]}
              websiteUrl={item[1]}
              onUploadUrlChange={(newValue) => onUploadUrlChange(i, newValue)}
              onWebsiteUrlChange={(newValue) => onWebsiteUrlChange(i, newValue)}
            />
          </div>
        ))}
      </form>
    </Dialog>
  );
};

export default TeamModal;
