import React, { useEffect, useState } from 'react';
import SvgIcon from 'components/svgIcon';

import OverviewModal from 'features/deals/OverviewModal';
import HighlightsModal from 'features/deals/HighlightsModal';
import BasicMetricsModal from 'features/deals/BasicMetricsModal';
import BlackDragonDealModal from 'features/deals/BlackDragonDealModal';
import RaiseStatsModal from 'features/deals/RaiseStatsModal';
import PartnersModal from 'features/deals/PartnersModal';
import TeamModal from 'features/deals/TeamModal';
import ProjectsModal from 'features/deals/ProjectsModal';
import { thousandSeparatorRound } from 'utils/helpers';
import Image from 'components/Image';
import DOMPurify from 'dompurify';
import './index.scss';

const DealInfoTab = ({
  overviewModalOpen,
  toggleOverviewModal,
  highlightsModalOpen,
  toggleHighlightsModal,
  basicMetricsModalOpen,
  toggleBasicMetricsModal,
  blackDragonDealModalOpen,
  toggleBlackDragonDealModal,
  raiseStatsModalOpen,
  toggleRaiseStatsModal,
  partnersModalOpen,
  togglePartnersModal,
  teamModalOpen,
  toggleTeamModal,
  projectsModalOpen,
  toggleProjectsModal,
  deal,
}) => {
  return (
    <div className="content info-content">
      <div className="content-row">
        <div className="col col-4 overview-col">
          {overviewModalOpen && (
            <OverviewModal open={overviewModalOpen} onClose={toggleOverviewModal} deal={deal} />
          )}
          <div className="edit-button" onClick={toggleOverviewModal}>
            <SvgIcon name="iconDealsEdit" />
          </div>
          <h3 className="col-title">OVERVIEW</h3>
          <div className="html-holder" tabIndex="0">
            <div
              className="inner-content"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(deal.overview) }}
            />
          </div>
          {deal.projectUrl && (
            <a
              className="external-link"
              href={deal.projectUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              Project details{' '}
              <span>
                <SvgIcon name="iconExternalLink" />
              </span>
            </a>
          )}
        </div>
        <div className="col col-4">
          {highlightsModalOpen && (
            <HighlightsModal
              open={highlightsModalOpen}
              onClose={toggleHighlightsModal}
              deal={deal}
            />
          )}
          <div className="edit-button" onClick={toggleHighlightsModal}>
            <SvgIcon name="iconDealsEdit" />
          </div>
          <h3 className="col-title">HIGHLIGHTS</h3>
          <div className="html-holder" tabIndex="0">
            <div
              className="inner-content"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(deal.highlights) }}
            />
          </div>
        </div>
        <div className="col col-4">
          {basicMetricsModalOpen && (
            <BasicMetricsModal
              open={basicMetricsModalOpen}
              onClose={toggleBasicMetricsModal}
              deal={deal}
            />
          )}
          <div className="edit-button" onClick={toggleBasicMetricsModal}>
            <SvgIcon name="iconDealsEdit" />
          </div>
          <h3 className="col-title">BASIC METRICS</h3>
          <div className="metrics-holder">
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Ticker:</p>
              </div>
              <div className="box-col col-right">
                <p>{deal.ticker || '-'}</p>
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Token Type:</p>
              </div>
              <div className="box-col col-right">
                <p>{deal.tokenType || '-'}</p>
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>TGE:</p>
              </div>
              <div className="box-col col-right">
                <p>{deal.tge || '-'}</p>
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Total Raise:</p>
              </div>
              <div className="box-col col-right">
                {deal.hardCap ? (
                  <p>{`${
                    deal.hardCapSymbol === '$' ? deal.hardCapSymbol : ''
                  } ${thousandSeparatorRound(deal.hardCap)}${
                    deal.hardCapSymbol !== '$' ? ` ${deal.hardCapSymbol}` : ''
                  }`}</p>
                ) : (
                  <p>-</p>
                )}
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Initial Circulating Supply:</p>
              </div>
              <div className="box-col col-right">
                <p>{deal.initCircSupply ? thousandSeparatorRound(deal.initCircSupply) : '-'}</p>
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Initial Market Cap:</p>
              </div>
              <div className="box-col col-right">
                <p>
                  {deal.initMarketCap ? `$ ${thousandSeparatorRound(deal.initMarketCap)}` : '-'}
                </p>
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Total Supply:</p>
              </div>
              <div className="box-col col-right">
                <p>{deal.totalySupply ? thousandSeparatorRound(deal.totalySupply) : '-'}</p>
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Fully Diluted Market Cap:</p>
              </div>
              <div className="box-col col-right">
                <p>
                  {deal.fullyDilutedMarketCap
                    ? `$ ${thousandSeparatorRound(deal.fullyDilutedMarketCap)}`
                    : '-'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-row">
        <div className="col col-4">
          {blackDragonDealModalOpen && (
            <BlackDragonDealModal
              open={blackDragonDealModalOpen}
              onClose={toggleBlackDragonDealModal}
              deal={deal}
            />
          )}
          <div className="edit-button" onClick={toggleBlackDragonDealModal}>
            <SvgIcon name="iconDealsEdit" />
          </div>
          <h3 className="col-title">BLACKDRAGON DEAL</h3>
          <div className="blackdragon-deal">
            <div className="deal-box">
              <div className="icon">
                <SvgIcon name="iconDealInfoAmount" />
              </div>
              <div className="box-info">
                <p className="info-label">Price</p>
                <p className="info-value">{deal.price ? `$${deal.price}` : '/'}</p>
              </div>
            </div>
            <div className="deal-box">
              <div className="icon">
                <SvgIcon name="iconDealInfoLockup" />
              </div>
              <div className="box-info">
                <p className="info-label">Round</p>
                <p className="info-value">{deal.round || '/'}</p>
              </div>
            </div>
            <div className="deal-box">
              <div className="icon">
                <SvgIcon name="iconDealInfoLockup" />
              </div>
              <div className="box-info">
                <p className="info-label">Vesting</p>
                <p className="info-value">{deal.vesting || '/'}</p>
              </div>
            </div>
            <div className="deal-box">
              <div className="icon">
                <SvgIcon name="iconDealInfoAmount" />
              </div>
              <div className="box-info">
                <p className="info-label">Fee</p>
                <p className="info-value">{deal.fee ? `${deal.fee}%` : '/'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-8">
          {raiseStatsModalOpen && (
            <RaiseStatsModal
              open={raiseStatsModalOpen}
              onClose={toggleRaiseStatsModal}
              deal={deal}
              raiseStats={deal.dealRaiseStats}
            />
          )}
          <div className="edit-button" onClick={toggleRaiseStatsModal}>
            <SvgIcon name="iconDealsEdit" />
          </div>
          <h3 className="col-title">RAISE STATS</h3>
          <div className="table-holder">
            <table>
              <thead>
                <tr>
                  <td colSpan="2">Round</td>
                  <td>Token Price</td>
                  <td>Tokens for Sale</td>
                  <td colSpan="3">Vesting</td>
                  <td>Amount</td>
                </tr>
              </thead>
              <tbody>
                {deal.dealRaiseStats?.map((item) => {
                  if (!item) return null;
                  return (
                    <tr key={item.id} className="raise-stats-row">
                      <td data-label="Round" colSpan="2">
                        {item.round}
                      </td>
                      <td data-label="Token Price">{item.tokenPrice}</td>
                      <td data-label="Tokens for Sale">{item.tokensSold}</td>
                      <td data-label="Vesting" colSpan="3">
                        {item.vesting}
                      </td>
                      <td data-label="Amount">{item.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="content-row">
        <div className="col col-8 partners-and-investors-container">
          {partnersModalOpen && (
            <PartnersModal
              open={partnersModalOpen}
              onClose={togglePartnersModal}
              deal={deal}
              partnersInvestors={deal.partnersInvestors}
            />
          )}
          <div className="edit-button" onClick={togglePartnersModal}>
            <SvgIcon name="iconDealsEdit" />
          </div>
          <h3 className="col-title">PARTNERS &amp; INVESTORS</h3>
          <div className="partners-and-investors">
            {deal.partnersInvestors?.map((partner) => {
              if (!partner.logo) return null;
              return (
                <div className="partners-and-investors-box-holder" key={partner.id}>
                  <div className="partners-and-investors-box">
                    <a href={partner.website} target="_blank" rel="noopener noreferrer">
                      <Image src={partner.logo} alt="Partners and investors logo" />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col col-4 transparent p-0">
          <div className="content-row m-0">
            <div className="col col-12">
              {teamModalOpen && (
                <TeamModal
                  open={teamModalOpen}
                  onClose={toggleTeamModal}
                  deal={deal}
                  teamAdvisors={deal.teamAdvisors}
                />
              )}
              <div className="edit-button" onClick={toggleTeamModal}>
                <SvgIcon name="iconDealsEdit" />
              </div>
              <h3 className="col-title">TEAM &amp; ADVISORS</h3>
              <div className="team-and-advisors">
                {deal.teamAdvisors?.map((advisor) => {
                  if (!advisor.logo) return null;
                  if (!advisor.website) {
                    return (
                      <div className="img-holder" key={advisor.id}>
                        <Image src={advisor.logo} alt="Team member" />
                      </div>
                    );
                  }
                  return (
                    <a
                      className="img-holder"
                      href={advisor.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={advisor.id}
                    >
                      <Image src={advisor.logo} alt="Team member" />
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="col col-12">
              {projectsModalOpen && (
                <ProjectsModal open={projectsModalOpen} onClose={toggleProjectsModal} deal={deal} />
              )}
              <div className="edit-button" onClick={toggleProjectsModal}>
                <SvgIcon name="iconDealsEdit" />
              </div>
              <h3 className="col-title">PROJECT LINKS</h3>
              <div className="project-links">
                {deal && deal.projectLinks && deal.projectLinks[0] && (
                  <a
                    href={deal.projectLinks[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconDealPitchDeck" />
                  </a>
                )}
                {deal && deal.projectLinks && deal.projectLinks[1] && (
                  <a
                    href={deal.projectLinks[1]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconDealWhitepaper" />
                  </a>
                )}
                <span className="img-holder">
                  <span className="vertical-line">|</span>
                </span>
                {deal && deal.projectLinks && deal.projectLinks[2] && (
                  <a
                    href={deal.projectLinks[2]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconProjectLinksWebsite" />
                  </a>
                )}
                {deal && deal.projectLinks && deal.projectLinks[3] && (
                  <a
                    href={deal.projectLinks[3]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconProjectLinksTwitter" />
                  </a>
                )}
                {deal && deal.projectLinks && deal.projectLinks[4] && (
                  <a
                    href={deal.projectLinks[4]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconProjectLinksTelegram" />
                  </a>
                )}
                {deal && deal.projectLinks && deal.projectLinks[5] && (
                  <a
                    href={deal.projectLinks[5]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconProjectLinksDiscord" />
                  </a>
                )}
                {deal && deal.projectLinks && deal.projectLinks[6] && (
                  <a
                    href={deal.projectLinks[6]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconProjectLinksBlog" />
                  </a>
                )}
                {deal && deal.projectLinks && deal.projectLinks[7] && (
                  <a
                    href={deal.projectLinks[7]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconProjectLinksLink" />
                  </a>
                )}
                {deal && deal.projectLinks && deal.projectLinks[8] && (
                  <a
                    href={deal.projectLinks[8]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="img-holder"
                  >
                    <SvgIcon name="iconProjectLinksLink" />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealInfoTab;
