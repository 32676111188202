import React, { useState } from 'react';
import RoundedButton from 'components/button/rounded-button';
import '../../index.scss';
import Tooltip from 'components/tooltip';
import { useSelector } from 'react-redux';
import usePledgeMutation from 'contracts/pledgeVault/hooks/usePledgeMutation';
import { useQueryClient } from 'react-query';
import usePledgePoolQuery from 'contracts/pledgeVault/hooks/usePledgePoolQuery';
import PledgeStatus from 'contracts/pledgeVault/constants';
import PledgeModal from '../../PledgeModal';
import ReleaseModal from '../../ReleaseModal';

export default function NewUserPledgeControl({ deal, onLoad }) {
  const authReducer = useSelector((state) => state.auth);
  const { accountInfo } = authReducer;

  const [noPledgeMessage, setNoPledgeMessage] = useState('');

  const [pledgeModalOpen, setPledgeModalOpen] = useState(false);
  const [releaseModalOpen, setReleaseModalOpen] = useState(false);

  const queryClient = useQueryClient();

  const handleNoPledge = (accountInfo) => {
    if (!accountInfo) return;
    if (accountInfo.userAccessLevel === -1 && accountInfo.relockMessage?.length) {
      setNoPledgeMessage(accountInfo.relockMessage);
      return;
    }

    setNoPledgeMessage('');
  };

  const pledgeMutation = usePledgeMutation(deal, {
    onSuccess: () => {
      onLoad();

      queryClient.invalidateQueries(['deal', deal.id]);

      setPledgeModalOpen(false);
    },
    onError: () => setPledgeModalOpen(false),
  });

  const { pool, isLoading: isPoolLoading } = usePledgePoolQuery(deal.address);

  if (![1, 7].includes(deal.statusId) || isPoolLoading) return null;

  return (
    <>
      <Tooltip title={noPledgeMessage}>
        <div
          className="deal__field deal__field-status-stepper vertical-center vertical-gap"
          onMouseEnter={() => handleNoPledge(accountInfo)}
        >
          <RoundedButton
            disabled={!deal.pledgeAmount || pool?.status !== PledgeStatus.OpenUnlocked}
            type="secondary"
            onClick={() => setReleaseModalOpen(true)}
          >
            Release
          </RoundedButton>
          <RoundedButton
            type="primary"
            disabled={accountInfo.userAccessLevel === -1}
            onClick={() => setPledgeModalOpen(true)}
          >
            Pledge
          </RoundedButton>
        </div>
      </Tooltip>
      <PledgeModal
        pledgeMutation={pledgeMutation}
        isOpen={pledgeModalOpen}
        onCancel={() => setPledgeModalOpen(false)}
        defaultValue={deal.pledge}
      />
      <ReleaseModal
        dealId={deal.id}
        poolAddress={deal.address}
        isOpen={releaseModalOpen}
        onCancel={() => setReleaseModalOpen(false)}
        onLoad={onLoad}
      />
    </>
  );
}
