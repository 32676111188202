import React, { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { updateDeal } from 'services/apiService';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';
import { Autocomplete } from '@material-ui/lab';
import { Box, TextField } from '@material-ui/core';
import remote from './remote';

const AdminModal = ({ open, onClose, deal, dealStatusInfo }) => {
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const queryClient = useQueryClient();
  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      dealStatusInfo: dealStatusInfo || '',
      coingeckoApiId: deal.coingeckoApiId || '',
    });
  }, [dealStatusInfo, deal.coingeckoApiId]);

  const onChangeData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const { data: statusInfoOptions } = remote.useGetAllStatusInfos();
  const createNewStatusInfo = remote.useCreateNewStatusInfo();
  const updateDealStatus = remote.useUpdateDealStatusInfo(+deal.id);

  const [newStatusInfo, setNewStatusInfo] = useState(dealStatusInfo ?? '');
  const onStatusInfoInputChange = (newValue) => setNewStatusInfo(newValue);

  const handleAddNewStatusInfo = async () => {
    try {
      await createNewStatusInfo.mutateAsync({ name: newStatusInfo });
      onChangeData({ target: { name: 'dealStatusInfo', value: newStatusInfo } });
      showSuccessNotification('Created new status info!');
    } catch (err) {
      console.log({ err });
      showErrorNotification('Failed to create new status info!');
    }
  };

  const getStatusInfoId = () => {
    if (!statusInfoOptions) return null;
    const textInputOption = statusInfoOptions.find((option) => option.name === newStatusInfo);
    if (textInputOption) return textInputOption.id;

    const selectedOption = statusInfoOptions.find((option) => option.name === data.dealStatusInfo);
    if (selectedOption) return selectedOption.id;

    return null;
  };

  const onConfirm = async (e) => {
    e.preventDefault();
    const statusInfoId = getStatusInfoId();
    const newData = {
      statusInfoId: statusInfoId || null,
      coingeckoApiId: data.coingeckoApiId || null,
    };
    try {
      await updateDeal(deal.id, { ...newData });
      await updateDealStatus.mutateAsync({ statusInfoId });

      queryClient.invalidateQueries(['deal-admin-data', deal.id]);
      queryClient.invalidateQueries(['admin-deal', deal.id]);

      showSuccessNotification(`Updated successfully`);
      onClose();
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  return (
    <Dialog open={open} className="deal-info-modal basic-metrics-modal" onClose={onClose}>
      <div className="modal-header">
        <div className="header-title">
          <SvgIcon name="iconDealsModalEdit" />
          <h3>Admin overview</h3>
        </div>
        <div className="header-actions">
          <RoundedButton type="transparent" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" buttonType="submit" form="basic-metrics-form">
            <div className="d-flex">Publish</div>
          </RoundedButton>
        </div>
      </div>
      <form className="modal-body" id="basic-metrics-form" onSubmit={onConfirm}>
        <div className="col col-left">
          <div className="input-holder">
            <label>Status info:</label>
            <Autocomplete
              freeSolo
              classes={{ root: 'deals-input' }}
              options={statusInfoOptions.map((o) => o.name)}
              renderInput={(params) => <TextField {...params} />}
              value={data.dealStatusInfo}
              onChange={(_, newValue) =>
                onChangeData({ target: { name: 'dealStatusInfo', value: newValue } })
              }
              onInputChange={(_, newInputValue) => onStatusInfoInputChange(newInputValue)}
            />
          </div>
          <Box marginBottom={4} />
          <RoundedButton
            type="primary"
            onClick={handleAddNewStatusInfo}
            disabled={
              !newStatusInfo ||
              statusInfoOptions.map((o) => o.name).includes(newStatusInfo) ||
              handleAddNewStatusInfo.isLoading
            }
          >
            <div className="d-flex">Add new</div>
          </RoundedButton>
        </div>
        <div className="col col-left">
          <div className="input-holder">
            <label>CoinGecko API:</label>
            <input name="coingeckoApiId" value={data.coingeckoApiId} onChange={onChangeData} />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default AdminModal;
