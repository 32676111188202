import React, { useState } from 'react';
import { getAddressLinkByChainId } from 'contracts/explorers';
import { ARBITRUM_CHAIN_ID } from 'constants/config';

import { roundNumberToDecimals } from 'utils/helpers';
import moment from 'moment';
import IconButton from 'components/button/icon-button';
import PledgeTxListModal from './PledgeTxListModal';

const PledgeTxList = ({ deal, pledges }) => {
  const [showAllTx, setShowAllTx] = useState(false);

  return (
    <div className="pledge-transactions">
      {pledges?.map((p) => (
        <div className="pledge-transaction-row" key={p.id.toString()}>
          <div className="pledge-transactions-col">
            <p>{moment(p.updatedAt).format('D-MMMM-YYYY, HH:mm:ss')}</p>
          </div>
          <div className="pledge-transactions-col address-col">
            <a
              href={getAddressLinkByChainId(ARBITRUM_CHAIN_ID, p.wallet)}
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {p.wallet}
            </a>
          </div>
          <div className="pledge-transactions-col amount-col">
            <p>{roundNumberToDecimals(Number(p.amount), 0)} USDT</p>
          </div>
          {!deal.useOldPledge ? (
            <div className="pledge-transactions-col show-all-col">
              <IconButton icon="iconTokensClaimed" onClick={() => setShowAllTx(true)} />
            </div>
          ) : null}
        </div>
      ))}
      <PledgeTxListModal open={showAllTx} deal={deal} onClose={() => setShowAllTx(false)} />
    </div>
  );
};

export default PledgeTxList;
