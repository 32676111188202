import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { updateLoading, addNotification, setActiveHashes } from 'store/actions';
import { DEFAULT_CHAIN_NAME } from 'constants/config';
import notificationTypes from 'constants/notificationTypes';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';
import { MutationArgs } from 'utils/types';
import usePoolContract from './usePoolContract';

const useWithdrawMutation = (poolAddress, { onSuccess, onError } = MutationArgs) => {
  const dispatch = useDispatch();

  const poolContract = usePoolContract(poolAddress);

  const queryClient = useQueryClient();

  const { mutateAsync: withdraw, isLoading, isError, error } = useMutation(
    async () => {
      const tx = await poolContract.withdraw();

      return tx.wait();
    },
    {
      onMutate: () => {
        dispatch(updateLoading(true));
      },
      onSuccess: (data) => {
        dispatch(updateLoading(false));
        dispatch(
          setActiveHashes([
            {
              hash: data.transactionHash,
              chain: DEFAULT_CHAIN_NAME,
              pending: false,
              callback: () => {
                queryClient.invalidateQueries(['my-withdraw']);

                onSuccess();
              },
            },
          ])
        );
        dispatch(
          addNotification({
            name: data.transactionHash,
            chain: DEFAULT_CHAIN_NAME,
            status: 'pending',
            statusText: 'Pending!',
            time: Date.now(),
            type: notificationTypes.GENERAL,
          })
        );
      },
      onError: () => {
        dispatch(updateLoading(false));
        dispatch(
          addNotification({
            name: 'Withdraw Error',
            status: 'error',
            statusText: 'Error!',
            time: Date.now(),
            chain: DEFAULT_CHAIN_NAME,
            type: notificationTypes.GENERAL,
          })
        );

        onError();
      },
    }
  );

  const checkedWithdraw = useWithCorrectNetwork(withdraw);

  return {
    withdraw: checkedWithdraw,
    isLoading,
    isError,
    error,
  };
};

export default useWithdrawMutation;
