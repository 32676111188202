import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import useWithdrawMutation from 'contracts/poolManager/hooks/useWithdrawMutation';
import useMyWithdrawQuery from 'contracts/poolManager/hooks/useMyWithdrawQuery';

const ClaimDealModal = ({ deal, open, onClose }) => {
  const { withdraw } = useWithdrawMutation(deal.address, {
    onSuccess: onClose,
    onError: onClose,
  });

  const { myWithdraw } = useMyWithdrawQuery(deal);

  return (
    <Dialog
      open={open}
      className="close-deal-modal unlock-lockup-modal"
      id="close-deal-modal"
      onClose={onClose}
    >
      <div className="close-deal-modal-header d-flex">
        <div className="close-deal-modal-header__left d-flex vertical-center">
          <div className="deal-name vertical-center">
            <div>
              <span>
                Claim <strong>{myWithdraw} USDT</strong>?
              </span>
            </div>
          </div>
        </div>
        <div className="close-deal-modal-header__right vertical-center">
          <RoundedButton type="primary" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton onClick={withdraw}>
            <div className="d-flex">Confirm</div>
          </RoundedButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ClaimDealModal;
