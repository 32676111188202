import React from 'react';
import RoundedButton from 'components/button/rounded-button';
import { DealStatus } from 'models/constants';
import PledgeStatus from 'contracts/pledgeVault/constants';
import useCreatePool from 'contracts/poolManager/hooks/useCreatePool';
import useOldClosePledge from './hooks/useOldClosePledge';
import useOldOpenPledge from './hooks/useOldOpenPledge';
import './index.scss';
import { useNotifications } from 'hooks';
import { useQueryClient } from 'react-query';

const OldPledgeControl = ({ deal }) => {
  const [showSuccessNotification] = useNotifications();

  const queryClient = useQueryClient();

  const { mutate: openPledge, isLoading: isOpenLoading } = useOldOpenPledge(deal.id);

  const { createPool, isLoading: isCreateLoading } = useCreatePool(deal.id, {
    onSuccess: () => {
      showSuccessNotification('Pledge opened');

      queryClient.invalidateQueries(['admin-deal', deal.id]);
      queryClient.invalidateQueries(['deal', deal.id]);
    },
  });

  const { mutate: closePledge, isLoading: isCloseLoading } = useOldClosePledge(deal.id);

  const onOpenPledge = () => {
    if (!deal.address) {
      createPool(PledgeStatus.Freezed);
      return;
    }

    openPledge();
  };

  const isLoading = isOpenLoading || isCloseLoading || isCreateLoading;

  return (
    <div className="old-pledge-control">
      <h2 className={`pledge-status-label ${deal.statusId === 7 ? 'active' : ''}`}>
        {deal.statusId === 7 ? 'OPEN' : 'CLOSED'}
      </h2>
      {[6, 7, 8].includes(deal.statusId) && (
        <div className="pledge-control-actions old-pledge-control-actions">
          <RoundedButton
            type="secondary"
            onClick={onOpenPledge}
            disabled={deal.statusId === 7 || isLoading}
          >
            Open
          </RoundedButton>
          <RoundedButton
            type="primary"
            onClick={closePledge}
            disabled={
              [DealStatus.PledgeClosed, DealStatus.None].includes(deal.statusId) || isLoading
            }
          >
            Close
          </RoundedButton>
        </div>
      )}
    </div>
  );
};

export default OldPledgeControl;
