import { queryCalls } from 'contracts/multicallContract';
import buildCall from 'contracts/calls';
import { decodeDealInfo } from 'models/common/decoders';
import { parseClaimerModels } from 'models/dealModel';
import { CALL_TYPE } from 'contracts/calls/constants';
import { getActivePhases } from 'services/apiService';
import { getProviderByChainId } from '../../contracts/providers';

export async function enrichWithMetadata(rawDeals) {
  return Promise.all(
    rawDeals.map(async (rawDeal) => {
      const { deal, dealChainData, currentPhaseIndex, personalCap, ...phaseData } = rawDeal;

      return getAdminDealMetadata({
        dealApi: deal,
        preFetchedData: { dealChainData, personalCap, ...phaseData },
      });
    })
  );
}

async function getAdminDealMetadata({ dealApi, preFetchedData = {} }) {
  // NOTE: deal has not been deployed yet
  if (!dealApi.address) {
    return dealApi;
  }

  const provider = getProviderByChainId(dealApi.chainId);
  const dealAddress = dealApi.address;

  const whitelists = [];

  const deal = {
    ...dealApi,
    ...parseClaimerModels(dealApi.claimers, dealAddress, null),
    status: (dealApi.vestingStatus ?? dealApi.status).toLowerCase(),
    id: dealApi.id.toString(),
    whitelists,
  };

  // check if we already have prefetched data
  let { dealChainData, personalCap } = preFetchedData;

  // if prefetched data is not available, batch dealChainData
  if (!dealChainData) {
    const calls = prepareCalls({ dealChainData, deal });

    const multicallResults = await queryCalls(provider, calls);

    if (!dealChainData) {
      dealChainData = getDealInfo(multicallResults);
    }
  }

  return {
    ...deal,
    ...dealChainData,
    personalCap,
  };
}

function getDealInfo(multicallResults) {
  const dealInfoResultIndex = 0;

  const rawDealInfo = multicallResults[dealInfoResultIndex].returnData;

  return decodeDealInfo(rawDealInfo);
}

function prepareCalls({ dealChainData, deal }) {
  const multicallCalls = [];

  if (!dealChainData) {
    multicallCalls.push(buildCall(CALL_TYPE.DEAL_INFO)(deal.address));
  }

  return multicallCalls;
}
