import React, { useState } from 'react';

import './index.scss';
import Tabs from 'components/tabs';
import useAMLTabs from './hooks/useAMLTabs';
import useUsersLegalData from './hooks/useUsersLegalData';
import { useAllHanfaReportQuery } from './screens/Reports/hooks/useAllHanfaReportQuery';

const UsersAndAML = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { data: users } = useUsersLegalData();

  const { reports } = useAllHanfaReportQuery({ enabled: selectedTabIndex === 1 });

  const tabs = useAMLTabs();

  const recordCount = selectedTabIndex === 1 ? reports?.length : users?.length;

  return (
    <div className="all-deals-page min-width-500">
      <div className="deals-header horizontal-between">
        <div className="deals-header-left d-flex">
          <div className="deals-header__title vertical-center">Users and AML</div>
          {recordCount && (
            <div className="deals-header__deals-cnt vertical-center total-count">
              <span>{recordCount} Total</span>
            </div>
          )}
        </div>
      </div>
      <Tabs
        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
      />
    </div>
  );
};

export default UsersAndAML;
