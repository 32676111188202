import { useQuery, useQueryClient } from 'react-query';
import { useNotifications } from 'hooks';

import { useSelector } from 'react-redux';
import { ethers } from 'ethers';
import usePoolContract from './usePoolContract';
import { DealVersion } from 'models/constants';

const useMyWithdrawQuery = (deal, options = {}) => {
  const walletAddress = useSelector((state) => state.auth.walletAddress);

  const queryClient = useQueryClient();

  const [, showErrorNotification] = useNotifications();

  const poolContract = usePoolContract(deal?.address);

  const { data: myWithdraw, isFetching } = useQuery(
    ['my-withdraw', deal?.address],
    async () => {
      const withdrawableAmountBN = await poolContract.getWithdrawableAmount(walletAddress);

      return ethers.utils.formatUnits(withdrawableAmountBN, 'mwei');
    },
    {
      ...options,
      enabled: !!deal?.address && deal.version === DealVersion.V3,
      onSuccess: (claimAmount) => {
        const dealToUpdate = queryClient.getQueryData(['deal', deal.id]);

        if (!dealToUpdate) {
          return;
        }

        dealToUpdate.claimAmount = claimAmount;

        queryClient.setQueryData(['deal', deal.id], dealToUpdate);
      },
      onError: () => {
        showErrorNotification('Error fetching my withdraw');
      },
    }
  );

  return {
    myWithdraw: myWithdraw || 0,
    isLoading: isFetching,
  };
};

export default useMyWithdrawQuery;
