import React from 'react';
import Tooltip from 'components/tooltip';
import IconButton from 'components/button/icon-button';
import PoolStatus from 'contracts/poolManager/constants';
import usePoolQuery from 'contracts/pledgeVault/hooks/usePoolQuery';
import { ActivePhasesModal } from './ActivePhasesModal';

function ActivePhases({ deal }) {
  const { pool } = usePoolQuery(deal);

  return pool.statusId === PoolStatus.Active && deal.activePhases?.length > 0 ? (
    <div className="deal__field-action">
      <Tooltip title={<ActivePhasesModal deal={deal} />}>
        <div className="show-phases-btn-container">
          <IconButton
            disabled={!deal.activePhases?.length > 0}
            icon="iconTimePast"
            className="show-phases-btn"
          />
          <span className="show-phases-btn-text">{deal.activePhases?.length} active</span>
        </div>
      </Tooltip>
    </div>
  ) : (
    <div className="deal__field-action" />
  );
}

export default ActivePhases;
