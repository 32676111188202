import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { updateLoading, setActiveHashes } from 'store/actions';

import { DEFAULT_CHAIN_NAME } from 'constants/config';
import useWithCorrectNetwork from 'hooks/useWithCorrectNetwork';
import { MutationArgs } from 'utils/types';
import { utils } from 'ethers';
import usePoolManager from './usePoolManager';

const useUpdatePoolConfig = (address, { onSuccess, onError } = MutationArgs) => {
  const dispatch = useDispatch();

  const poolManager = usePoolManager();

  const queryClient = useQueryClient();

  const { mutateAsync: updatePoolConfig, isLoading } = useMutation(
    async (config) => {
      if (!address) {
        throw new Error('Pool not initialized!');
      }

      const tx = await poolManager.updatePoolConfiguration(address, config);

      const receipt = await tx.wait();

      const event = receipt.events.find((e) => e.event === 'PoolConfigurationUpdated');

      if (!event) {
        throw new Error('Pool configuration not updated');
      }

      return {
        receipt,
        pool: event.args.pool,
        poolConfig: event.args.poolConfig,
      };
    },
    {
      onMutate: () => {
        dispatch(updateLoading(true));
      },
      onSuccess: ({ receipt, pool, poolConfig }) => {
        dispatch(updateLoading(false));
        dispatch(
          setActiveHashes([
            {
              chain: DEFAULT_CHAIN_NAME,
              hash: receipt.transactionHash,
              callback: () => {
                queryClient.setQueryData(['pool-info', pool], (old) => ({
                  ...old,
                  dealSize: utils.formatUnits(poolConfig.dealSize, 'mwei'),
                  minContribution: utils.formatUnits(poolConfig.minContribution, 'mwei'),
                  maxContribution: utils.formatUnits(poolConfig.maxContribution, 'mwei'),
                }));

                if (onSuccess) onSuccess();
              },
              pending: false,
            },
          ])
        );
      },
      onError: () => {
        dispatch(updateLoading(false));

        if (onError) onError();
      },
    }
  );

  const checkedUpdatePoolConfig = useWithCorrectNetwork(updatePoolConfig);

  return {
    updatePoolConfig: checkedUpdatePoolConfig,
    isLoading,
  };
};

export default useUpdatePoolConfig;
