import React from 'react';
import RoundedButton from 'components/button/rounded-button';
import useDealLivePromotion from 'contracts/hooks/useDealLivePromotion';
import { useDeployContractContext } from '../context';
import { isValidPoolConfig } from '../helpers';

function DeployContractAction({ disabled, onValidate }) {
  const { deal, poolConfig, isValidPledgeStatus, arePhasesValid } = useDeployContractContext();

  const { promoteDeal, isLoading: isPromotingDeal } = useDealLivePromotion(deal);

  const onPromoteDeal = () => {
    const { isFormValid } = onValidate();

    if (!isFormValid) {
      return;
    }

    promoteDeal();
  };

  return (
    <RoundedButton
      onClick={onPromoteDeal}
      disabled={
        isPromotingDeal ||
        !isValidPledgeStatus ||
        !isValidPoolConfig(poolConfig) ||
        !arePhasesValid ||
        disabled
      }
      type="secondary"
    >
      Deploy
    </RoundedButton>
  );
}

export default DeployContractAction;
