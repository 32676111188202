import React from 'react';
import PledgeStatus from 'contracts/pledgeVault/constants';
import usePledgePoolQuery from 'contracts/pledgeVault/hooks/usePledgePoolQuery';
import { useQueryClient } from 'react-query';
import PoolCreateButton from '../../../PoolCreateButton';
import PledgeActionsDropdown from '../../../PledgeActionsDropdown';
import { PledgeLabel } from '../../constants';

export const PledgeLabelColor = {
  [PledgeStatus.NotActive]: 'white',
  [PledgeStatus.OpenUnlocked]: 'green',
  [PledgeStatus.OpenLocked]: 'green',
  [PledgeStatus.Freezed]: 'white',
  [PledgeStatus.Canceled]: 'red',
};

const NewPledgeControl = ({ deal }) => {
  const { pool, isLoading: isPoolLoading } = usePledgePoolQuery(deal.address);

  const queryClient = useQueryClient();

  const onSuccess = (poolAddress) => {
    queryClient.invalidateQueries(['admin-deal', deal.id]);
    queryClient.invalidateQueries(['deal', deal.id]);
    queryClient.invalidateQueries(['pool', poolAddress]);
  };

  return (
    <>
      {isPoolLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="vertical-flex pledge-control-container">
          <h2 className={`${PledgeLabelColor[pool?.status || 0]} mb-10`}>
            {PledgeLabel[pool?.status || 0].toUpperCase()}
          </h2>
          <div className="horizontal-flex justify-space-between">
            {!pool ? (
              <PoolCreateButton dealId={deal.id} onCreate={onSuccess} />
            ) : (
              <PledgeActionsDropdown dealId={deal.id} pool={{ ...pool, address: deal.address }} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default NewPledgeControl;
