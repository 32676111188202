import { useNotifications } from 'hooks';
import { useMutation, useQueryClient } from 'react-query';
import { postPledge } from 'services/apiService';

function useOldPledgeMutation(dealId, { onSuccess }) {
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const queryClient = useQueryClient();

  const {
    mutateAsync: pledge,
    isLoading,
    isError,
    error,
  } = useMutation({
    mutationFn: (data) => postPledge(dealId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', dealId]);

      showSuccessNotification('Pledged successfully');

      if (onSuccess) onSuccess();
    },
    onError: () => {
      showErrorNotification('Error while pledging');
    },
  });

  return {
    pledge,
    isLoading,
    isError,
    error,
  };
}

export default useOldPledgeMutation;
