import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { sortDeals } from 'utils/helpers';
import RoundedButton from 'components/button/rounded-button';
import { usePagination } from 'hooks';
import Tooltip from 'components/tooltip';
import DealEditRow from './DealEditRow';
import DealRow from './DealRow';
import './index.scss';
import DraftDealRow from './DraftDealRow';

function UserDealsTable({ userDeals, handleDealsLength, dealsFilter, setDealsFilter, hideTabs }) {
  const [sortDirection, setSortDirection] = useState('');
  const [sortColumn, setSortColumn] = useState('');
  const [deals, setDeals] = useState([]);
  const globalReducer = useSelector((state) => state.global);
  const authReducer = useSelector((state) => state.auth);
  const { activeDeal, isInitialLoadFinished } = globalReducer;
  const { Pagination, paginatedItems, resetPage } = usePagination(deals, 50);

  const getFilteredDeals = () => {
    if (dealsFilter === 'pledges') {
      const filteredDeals = userDeals.filter((deal) => [7, 8, 10].includes(deal.statusId));
      handleDealsLength(filteredDeals.length);
      return filteredDeals;
    }

    const filteredDeals = userDeals.filter(
      (deal) =>
        (deal.status === 'opened' || deal.status === 'paused' || deal.status === 'Live') &&
        ![0, 6, 7, 8, 10].includes(deal.statusId)
    );
    handleDealsLength(filteredDeals.length);
    return filteredDeals;
  };

  const onSelectFilter = (val) => {
    setSortDirection('');
    setSortColumn('');
    if (val !== dealsFilter) setDealsFilter(val);
  };

  const getSortArrow = (val) => {
    if (val !== sortColumn) return null;
    if (sortDirection === '') return null;
    if (sortDirection === 'desc') return <span style={{ color: '#e9504f' }}>&nbsp;&uarr;</span>;
    if (sortDirection === 'asc') return <span style={{ color: '#e9504f' }}>&nbsp;&darr;</span>;
  };

  const getNextSortDirection = () => {
    if (sortDirection === 'asc') {
      return 'desc';
    }
    if (sortDirection === 'desc') {
      return '';
    }
    return 'asc';
  };

  const onSortDeals = (field, isNumber, isPledge) => {
    const nextSortDirection = getNextSortDirection();
    setSortDirection(nextSortDirection);
    setSortColumn(field);
    setDeals(sortDeals(field, deals, nextSortDirection, isPledge, isNumber));
  };

  const isVerified =
    authReducer.idVerified === 'verification_approved' || authReducer.idVerified === 'approved';

  const lockedAmount = authReducer.accountInfo?.lockInfo?.amount;

  const noDealMessage = (() => {
    if (!isInitialLoadFinished) {
      return 'Loading...';
    }
    if (!isVerified && !lockedAmount) {
      return 'Please complete the KYC in your profile tab and lock BDT tokens to access the deals.';
    }
    if (!isVerified) {
      return 'Please complete the KYC in your profile tab to access the deals.';
    }
    if (!lockedAmount) {
      return 'Please lock BDT tokens in order to access the deals.';
    }
    if (deals.length === 0 && isInitialLoadFinished && dealsFilter === 'active') {
      return 'There are no live deals currently. Stay tuned and check later.';
    }
    return '';
  })();

  useEffect(() => {
    setDeals(sortDeals('dateCreated', getFilteredDeals(), '', false, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDeals, dealsFilter]);

  useEffect(() => {
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealsFilter]);

  return (
    <div className="deals-table">
      {!hideTabs && (
        <div className="deals-table-options d-flex">
          {isVerified && (
            <div className="filter-btn-wrapper">
              <RoundedButton
                className={`filter-btn ${dealsFilter === 'active' ? 'filter-btn--active' : ''}`}
                onClick={() => onSelectFilter('active')}
              >
                <span>Live</span>
              </RoundedButton>
            </div>
          )}
          {isVerified && (
            <div className="filter-btn-wrapper">
              <RoundedButton
                className={`filter-btn ${dealsFilter === 'pledges' ? 'filter-btn--active' : ''}`}
                onClick={() => onSelectFilter('pledges')}
              >
                <span>Pledges</span>
              </RoundedButton>
            </div>
          )}
        </div>
      )}
      <div className="deals-table-content">
        {dealsFilter !== 'pledges' && (
          <>
            <div className="deals-table-header d-flex">
              <div className="deal__field deal__field-avatar vertical-center" />
              <div
                className="deal__field deal__field-name vertical-center"
                onClick={() => onSortDeals('name')}
              >
                PROJECT
                {getSortArrow('name')}
              </div>
              <div
                className="deal__field deal__field-status vertical-center"
                onClick={() => onSortDeals('status')}
              >
                STATUS
                {getSortArrow('status')}
              </div>
              <div
                className="deal__field deal__field-raised-amount vertical-center"
                onClick={() => onSortDeals('raisedAmount', true)}
              >
                FILLED
                {getSortArrow('raisedAmount')}
              </div>
              <div
                className="deal__field deal__field-size vertical-center"
                onClick={() => onSortDeals('dealSize', true)}
              >
                DEAL SIZE
                {getSortArrow('dealSize')}
              </div>
              <div
                className="deal__field deal__field-maximum vertical-center"
                onClick={() => onSortDeals('personalCap', true)}
              >
                <Tooltip
                  title={
                    <>
                      <p>
                        Your maximum eligible contribution limit in USDT during the current raise
                        model.
                      </p>
                      <p>
                        This value dynamically updates based on the locked BDT supply and remaining
                        deal allocation.
                      </p>
                    </>
                  }
                >
                  <span>PERSONAL MAX{getSortArrow('personalCap')}</span>
                </Tooltip>
              </div>
              <div
                className="deal__field deal__field-contribution vertical-center"
                onClick={() => onSortDeals('contributedAmount', 'number')}
              >
                <span>MY CONTRIBUTION{getSortArrow('contributedAmount')}</span>
              </div>
              <div className="deal_field-actions" />
            </div>

            <div>
              {noDealMessage ? (
                <div className="deals-table-content__no-deals">
                  <h1>{noDealMessage}</h1>
                </div>
              ) : (
                paginatedItems.map((deal) => (
                  <div
                    key={deal.id}
                    className={`deals-table-content__row ${
                      activeDeal && activeDeal.id === deal.id
                        ? 'deals-table-content__row--active'
                        : ''
                    }`}
                  >
                    {activeDeal && activeDeal.id === deal.id ? (
                      <DealEditRow deal={deal} />
                    ) : (
                      <DealRow deal={deal} />
                    )}
                  </div>
                ))
              )}
            </div>
          </>
        )}
        {dealsFilter === 'pledges' && (
          <>
            <div className="deals-table-header draft-deal-table-header d-flex">
              <div className="deal__field deal__field-avatar vertical-center" />
              <div
                className="deal__field deal__field-name vertical-center"
                onClick={() => onSortDeals('name', null, true)}
              >
                PROJECT
                {getSortArrow('name')}
              </div>
              <div
                className="deal__field deal__field-status vertical-center"
                onClick={() => onSortDeals('dateCreated', null, false)}
              >
                PLEDGE DATE{getSortArrow('dateCreated')}
              </div>
              <div
                className="deal__field deal__field-status vertical-center width-15"
                onClick={() => onSortDeals('status', null, true)}
              >
                STATUS{getSortArrow('status')}
              </div>
              <div
                className="deal__field deal__field-pledge-amount vertical-center"
                onClick={() => onSortDeals('pledgeAmount', null, false)}
              >
                PLEDGE AMOUNT{getSortArrow('pledgeAmount')}
              </div>
              <div
                className="deal__field deal__field-pledge-amount vertical-center"
                onClick={() => onSortDeals('pledgeOriginalAmount', null, false)}
              >
                ACCEPTED PLEDGE{getSortArrow('pledgeOriginalAmount')}
              </div>
              <div className="deal__field deal__field-pledge-amount vertical-center">
                PLEDGE TYPE
              </div>
              <div
                className="deal__field deal__field-pledge-amount vertical-center"
                onClick={() => onSortDeals('useOldPledge', null, false)}
              >
                PLEDGE MODE{getSortArrow('useOldPledge')}
              </div>
            </div>
            <div>
              {paginatedItems.map((deal) => {
                return (
                  <div
                    key={deal.id}
                    className={`deals-table-content__row ${
                      activeDeal && activeDeal.id === deal.id
                        ? 'deals-table-content__row--active'
                        : ''
                    }`}
                  >
                    <DraftDealRow deal={deal} />
                  </div>
                );
              })}
            </div>
          </>
        )}
        <Pagination />
      </div>
    </div>
  );
}

UserDealsTable.propTypes = {
  userDeals: PropTypes.arrayOf(PropTypes.shape()),
};

UserDealsTable.defaultProps = {
  userDeals: [],
};

export default React.memo(UserDealsTable);
