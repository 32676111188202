import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Divider } from '@material-ui/core';
import useRoleAssign from 'features/roleManagement/hooks/useRoleAssign';
import useRoleRevoke from 'features/roleManagement/hooks/useRoleRevoke';
import RoleSelection from '../RoleSelection';
import UserRoleDialogHeader from './UserRoleDialogHeader';
import UserInputFields from './UserInputFields';

const AddUserRoleDialog = ({ open, onCancel, roles, userToEdit, setUserToEdit }) => {
  const [userRoleToAdd, setUserRoleToAdd] = useState({
    username: '',
    email: '',
    address: '',
    role: roles?.[0],
  });

  const { assignRole, isLoading: isAssigningRole } = useRoleAssign(userRoleToAdd, {
    onSuccess: () => {
      setUserToEdit(null);
      onCancel();
    },
    onError: onCancel,
  });

  const { revokeRole, isLoading: isRevokingRole } = useRoleRevoke(userToEdit?.wallet, {
    onSuccess: assignRole,
    onError: onCancel,
  });

  useEffect(() => {
    if (userToEdit) {
      setUserRoleToAdd({
        username: userToEdit.name,
        email: userToEdit.email,
        address: userToEdit.wallet,
        role: roles?.find((role) => role?.name === userToEdit?.role),
      });
    }
  }, [roles, userToEdit]);

  useEffect(() => {
    if (roles) {
      setUserRoleToAdd((prev) => ({
        ...prev,
        role: roles?.[0],
      }));
    }

    roles.sort((r1, r2) => r1.name.toLowerCase().localeCompare(r2.name.toLowerCase()));
  }, [roles]);

  const handleSave = async () => {
    if (userToEdit) {
      // Update logic
      await revokeRole();
    } else {
      // Assign new user role logic
      await assignRole();
    }
  };

  const handleCancel = () => {
    setUserRoleToAdd({
      username: '',
      email: '',
      address: '',
      role: roles?.[0],
    });
    setUserToEdit(null);
    onCancel();
  };

  return (
    <Dialog open={open} onClose={onCancel} classes={{ paper: 'add-user-modal' }}>
      <UserRoleDialogHeader
        onSave={handleSave}
        onCancel={handleCancel}
        disabled={isAssigningRole || isRevokingRole}
      />
      <DialogContent>
        <Divider />
        <UserInputFields
          userRoleToAdd={userRoleToAdd}
          setUserRoleToAdd={setUserRoleToAdd}
          userToEdit={userToEdit}
        />
        <RoleSelection
          roles={roles}
          userRoleToAdd={userRoleToAdd}
          setUserRoleToAdd={setUserRoleToAdd}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddUserRoleDialog;
