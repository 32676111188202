import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DealSearchInput from 'features/deals/DealSearchInput';
import UserDealsTable from 'features/deals/UserDealsTable';
import AdminDealsTable from 'features/deals/AdminDealsTable';
import PledgeWithdraw from 'features/deals/PledgeWithdraw/PledgeWithdraw';

function Pledges() {
  const [dealsLength, setDealsLength] = useState(0);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [dealFilter, setDealFilter] = useState('pledges');
  const [showInput, setShowInput] = useState(false);
  const authReducer = useSelector((state) => state.auth);
  const globalReducer = useSelector((state) => state.global);
  const { isAdmin } = authReducer;
  const { userDeals } = globalReducer;

  useEffect(() => {
    setFilteredDeals(userDeals);
  }, [userDeals]);

  return (
    <>
      <div className="deals-container">
        <div className="deals-header">
          <div className="deals-header-left d-flex">
            <div className="deals-header__title vertical-center">Pledges</div>
            <div className="deals-header__deals-cnt vertical-center">
              <span>{`${dealsLength} Total`}</span>
            </div>
          </div>
          <div className={`deals-header-right d-flex${showInput ? ' active' : ''}`}>
            <div className={`search-input-container vertical-center${showInput ? ' active' : ''}`}>
              <DealSearchInput
                deals={userDeals}
                onChange={setFilteredDeals}
                showInput={showInput}
                setShowInput={setShowInput}
              />
            </div>
          </div>
        </div>
        {!isAdmin ? <PledgeWithdraw /> : null}
        <div className="deals-content">
          {isAdmin ? (
            <AdminDealsTable
              userDeals={filteredDeals}
              handleDealsLength={setDealsLength}
              dealsFilter={dealFilter}
              setDealsFilter={setDealFilter}
            />
          ) : (
            <UserDealsTable
              userDeals={filteredDeals}
              handleDealsLength={setDealsLength}
              dealsFilter="pledges"
              hideTabs
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Pledges;
