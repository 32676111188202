import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { ethers } from 'ethers';
import RoundedButton from 'components/button/rounded-button';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import CustomProgressBar from 'components/progress-bar/custom-progress-bar';
import CustomSlider from 'components/progress-bar/custom-slider';
import CustomInput from 'components/input/custom-input';
import NumberInput from 'components/input/number-input';
import Tooltip from 'components/tooltip';
import './index.scss';
import { ADMIN_ADDRESS } from 'constants/config';
import { useSelector } from 'react-redux';
import { getAddressLinkByChainId } from 'contracts/explorers';
import useMaxCloseAmountQuery from 'features/roleManagement/hooks/useMaxCloseAmountQuery';

const CloseDealModal = ({ open, isPending, deal, onOk, onClose, handleToggleCloseModal }) => {
  const [closeAmount, setCloseAmount] = useState('0');

  const authReducer = useSelector((state) => state.auth);
  const { walletAddress } = authReducer;
  const [destinationAddresses, setDestinationAddresses] = useState([
    {
      id: 1,
      value: '',
      amount: '',
    },
  ]);
  const isAdmin = walletAddress.toLowerCase() === ADMIN_ADDRESS.toLowerCase();

  const { maxCloseAmount } = useMaxCloseAmountQuery(walletAddress);

  useEffect(() => {
    setCloseAmount(Number(deal.raisedAmount).toString());
  }, [deal]);

  const onChangeCloseAmount = (e, id) => {
    if (destinationAddresses?.length > 1) {
      let { value } = e.target;
      const items = [...destinationAddresses];
      const item = items.find((x) => x.id === id);
      if (Number(value) > Number(deal.raisedAmount)) {
        value = value.slice(0, -1);
        return;
      }
      item.amount = value;
      let tempAmount = 0;
      items.forEach((x) => {
        tempAmount += Number(x.amount);
      });
      const totalAmount = tempAmount.toString();
      if (Number(totalAmount) > Number(deal.raisedAmount)) {
        value = value.slice(0, -1);
        return;
      }
      setDestinationAddresses(items);
      setCloseAmount(totalAmount);
    } else {
      const { value } = e.target;
      if (Number(value) > Number(deal.raisedAmount)) return;
      setCloseAmount(value);
    }
  };

  const onChangeSlider = (_event, val) => {
    setCloseAmount(val.toString());
  };

  const onChangeDestinationAddress = (e, id) => {
    if (destinationAddresses?.length > 1) {
      const { value } = e.target;
      const items = [...destinationAddresses];
      const item = items.find((x) => x.id === id);
      item.value = value;

      setDestinationAddresses(items);
    } else {
      const { value } = e.target;
      const items = [...destinationAddresses];
      const item = items[0];
      item.value = value;

      setDestinationAddresses(items);
    }
  };

  const onConfirm = async () => {
    if (!destinationAddresses.length) {
      return;
    }

    if (destinationAddresses.some((x) => !ethers.utils.isAddress(x.value))) {
      return;
    }

    const addresses = destinationAddresses.map((x) => x.value);
    const amounts = destinationAddresses.map((x) => x.amount);
    await onOk(deal, destinationAddresses.length > 1 ? amounts : [closeAmount], addresses);
    if (handleToggleCloseModal) {
      handleToggleCloseModal();
    }
  };

  const getAddressStatus = (val) => {
    if (val.length === 0) return '';
    return ethers.utils.isAddress(val) ? 'success' : 'error';
  };

  const addAddress = () => {
    if (destinationAddresses?.length < 5) {
      setDestinationAddresses([
        ...destinationAddresses,
        {
          id: destinationAddresses[destinationAddresses.length - 1].id + 1,
          value: '',
          amount: '',
        },
      ]);
    }
  };

  const removeLastAddress = () => {
    setDestinationAddresses(
      destinationAddresses.filter((item) => item.id !== [...destinationAddresses].pop().id)
    );
  };

  return (
    <Dialog open={open} className="close-deal-modal" id="close-deal-modal" onClose={onClose}>
      <div className="close-deal-modal-header d-flex">
        <div className="close-deal-modal-header__left d-flex vertical-center">
          <div className="deal-avatar vertical-center">
            <a
              href={getAddressLinkByChainId(deal.chainId, deal.address)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <RoundedAvatar src={deal.imageUrl} />
            </a>
          </div>
          <div className="deal-name vertical-center">
            <div className="full-width">
              <span>{deal.name}</span>
              <CustomProgressBar
                total={Number(deal.raisedAmount)} // see is this is correct
                value={Number(deal.raisedAmount)}
              />
            </div>
          </div>
        </div>
        <div className="close-deal-modal-header__right vertical-center">
          <RoundedButton disabled={isPending} onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton
            type="secondary"
            disabled={
              isPending ||
              Number(closeAmount) <= 0 ||
              (!isAdmin && maxCloseAmount < Number(closeAmount)) ||
              !destinationAddresses.some((address) => address.value)
            }
            onClick={onConfirm}
          >
            <Tooltip
              title={maxCloseAmount < Number(closeAmount) && !isAdmin ? 'Role limit exceeded' : ''}
            >
              <div className="d-flex">Confirm</div>
            </Tooltip>
          </RoundedButton>
        </div>
      </div>
      <div className="close-deal-modal-body">
        <div className="d-flex close-amount">
          <div className="close-amount-slider vertical-center">
            <CustomSlider
              value={closeAmount}
              min={1}
              max={Number(deal.raisedAmount) || 0}
              onChange={onChangeSlider}
            />
          </div>
          {destinationAddresses?.length === 1 && (
            <div className="close-amount-input vertical-center">
              <span>
                <NumberInput
                  placeholder=""
                  value={closeAmount}
                  onChange={onChangeCloseAmount}
                  decimalNumber="6"
                />
              </span>
              <span className="close-amount-input-unit">USDT</span>
            </div>
          )}
        </div>
        {destinationAddresses.map((address) => {
          return (
            <div className="destination-address" key={address.id}>
              <CustomInput
                label="Destination Address"
                name="destinationAddress"
                value={address.value}
                status={getAddressStatus(address.value)}
                onChange={(e) => onChangeDestinationAddress(e, address.id)}
              />
              {destinationAddresses?.length > 1 && (
                <div className="number-input-holder">
                  <NumberInput
                    placeholder=""
                    value={address?.amount?.toString()}
                    onChange={(e) => onChangeCloseAmount(e, address.id)}
                    decimalNumber="6"
                  />
                  <span className="close-amount-input-unit">USDT</span>
                </div>
              )}
            </div>
          );
        })}
        <div className="addresses-actions">
          <button type="button" className="rounded-btn rounded-btn--default" onClick={addAddress}>
            Add row
          </button>
          {destinationAddresses?.length > 1 && (
            <button
              type="button"
              className="rounded-btn rounded-btn--default"
              onClick={removeLastAddress}
            >
              Remove row
            </button>
          )}
        </div>
      </div>
      <div className="close-deal-modal-footer">
        <div className="closing-amount">
          <CustomInput label="Closing Amount" thousandSeparator value={closeAmount} disabled />
        </div>
        <div className="closing-token">
          <CustomInput label="Closing Token" isText value="USDT" disabled />
        </div>
      </div>
    </Dialog>
  );
};

CloseDealModal.propTypes = {
  open: PropTypes.bool,
  isPending: PropTypes.bool,
  deal: PropTypes.shape(),
  onClose: PropTypes.func,
  onOk: PropTypes.func,
};

CloseDealModal.defaultProps = {
  open: false,
  isPending: false,
  deal: {},
  onClose: () => {},
  onOk: () => {},
};

export default CloseDealModal;
