import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import NumberInput from 'components/input/number-input';
import DatePicker from 'react-datepicker';
import React, { useMemo } from 'react';
import RoundedButton from 'components/button/rounded-button';
import VestingItemsDeleteModal from './VestingItemsDeleteModal';
import './index.scss';
import { thousandSeparatorRound } from 'utils';

const VestingTable = ({
  vesting,
  setVesting,
  expectedTokens,
  handleDismiss,
  handleSave,
  isDirty,
  readOnly,
  deleteVestingItems,
  onVestingItemsDelete,
  isDeleteVestingItemsModalOpen,
  closeDeleteVestingItemsModal,
}) => {
  const cleanAmount = (val) => {
    if (!val) return '';
    const clean = val.toString().replace(/,/g, '');
    return clean;
  };

  const handlePercentageChange = (e, i) => {
    if (readOnly) return;
    e.persist();

    const percentage = e.target.value;
    if (Number.isNaN(percentage)) return;

    const amount = expectedTokens && expectedTokens * (percentage / 100);
    setVesting((prev) => ({
      ...prev,
      calendar: prev.calendar.map((item, j) =>
        i === j
          ? {
              ...item,
              percentage,
              amount,
              isCustom: true,
            }
          : item
      ),
    }));
  };

  const handlePriceChange = (e, i) => {
    if (readOnly) return;
    e.persist();

    const amount = cleanAmount(e.target.value);
    if (Number.isNaN(amount)) {
      setVesting((prev) => ({
        ...prev,
        calendar: prev.calendar.map((item, j) =>
          i === j
            ? {
                ...item,
                amount: e.target.value,
                isCustom: true,
              }
            : item
        ),
      }));
      return;
    }

    const percentage = Math.round((amount / expectedTokens) * 100 * 1000000) / 1000000;
    setVesting((prev) => ({
      ...prev,
      calendar: prev.calendar.map((item, j) =>
        i === j
          ? {
              ...item,
              amount,
              percentage,
              isCustom: true,
            }
          : item
      ),
    }));
  };

  const handleDateChange = (date, i) => {
    if (readOnly) return;

    setVesting((prev) => ({
      ...prev,
      calendar: prev.calendar
        .map((item, j) =>
          i === j
            ? {
                ...item,
                date,
                isCustom: true,
              }
            : item
        )
        .sort((a, b) => (a.date > b.date ? +1 : -1)),
    }));
  };

  const [totalPercentage, totalAmount] = useMemo(() => {
    if (!vesting) return [0, 0];
    return vesting.calendar.reduce(
      (accum, { percentage, amount }) => [accum[0] + +percentage, accum[1] + +amount],
      [0, 0]
    );
  }, [vesting]);

  if (!vesting) return null;

  return (
    <>
      <TableContainer classes={{ root: 'vesting-tab__table' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Batch</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Vesting (%)</TableCell>
              {!!expectedTokens && <TableCell align="center">Amount</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {vesting.calendar.map(({ date, percentage, amount }, i) => (
              <TableRow key={date?.toString()}>
                <TableCell>
                  <div>{i + 1}</div>
                </TableCell>
                <TableCell align="center">
                  <DatePicker
                    readOnly={readOnly}
                    disabled={readOnly}
                    selected={date}
                    onChange={(changedDate) => handleDateChange(changedDate, i)}
                    dateFormat="dd-MMM-yyyy"
                  />
                </TableCell>
                <TableCell align="center">
                  <NumberInput
                    disabled={readOnly}
                    value={percentage}
                    onChange={(e) => handlePercentageChange(e, i)}
                    decimalNumber="6"
                  />
                </TableCell>
                {!!expectedTokens && (
                  <TableCell align="center">
                    <NumberInput
                      disabled={readOnly}
                      value={thousandSeparatorRound(parseInt(amount, 10))}
                      onChange={(e) => handlePriceChange(e, i)}
                      decimalNumber="18"
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!readOnly && (
        <div className="vesting-tab__footer">
          <div>
            {totalPercentage ? <span>Vesting(%) SUM: {totalPercentage}%</span> : null}
            {totalAmount ? (
              <span>Amount SUM: {thousandSeparatorRound(parseInt(totalAmount))}</span>
            ) : null}
          </div>
          <div>
            <RoundedButton type="transparent" onClick={handleDismiss}>
              Dismiss
            </RoundedButton>
            <RoundedButton type="primary" disabled={!isDirty} onClick={handleSave}>
              Confirm
            </RoundedButton>
          </div>
        </div>
      )}
      <VestingItemsDeleteModal
        deleteVestingItems={deleteVestingItems}
        onVestingItemsDelete={onVestingItemsDelete}
        isModalOpen={isDeleteVestingItemsModalOpen}
        closeModal={closeDeleteVestingItemsModal}
      />
    </>
  );
};

export default VestingTable;
