import { useQuery } from 'react-query';
import { useNotifications } from 'hooks';

import { useSelector } from 'react-redux';
import { ethers } from 'ethers';
import { DealVersion } from 'models/constants';
import usePoolContract from './usePoolContract';

const useRemainingAllocationsQuery = (deal, { phaseIds, allowListDatas }, options = {}) => {
  const walletAddress = useSelector((state) => state.auth.walletAddress);

  const [, showErrorNotification] = useNotifications();

  const poolContract = usePoolContract(deal?.address);

  const { data: remainingAllocations, isFetching } = useQuery(
    ['remaining-allocations', deal.address, walletAddress],
    async () => {
      const remainingAllocationsBN = await poolContract.getRemainingUserAllocationForPhases(
        walletAddress,
        phaseIds,
        allowListDatas
      );

      const remainingAllocations = remainingAllocationsBN.map((allocation) =>
        ethers.utils.formatUnits(allocation, 'mwei')
      );

      // RESERVED_PHASE_ID has index 0
      return [0, ...remainingAllocations];
    },
    {
      ...options,
      enabled: !!deal?.address && deal.version === DealVersion.V3,
      onError: () => {
        showErrorNotification('Error fetching my contribution');
      },
    }
  );

  return {
    remainingAllocations,
    isLoading: isFetching,
  };
};

export default useRemainingAllocationsQuery;
