import React, { useState, useEffect } from 'react';
import { updateDeal } from 'services/apiService';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';
import { useQueryClient } from 'react-query';

const ProjectsModal = ({ open, onClose, deal }) => {
  const [data, setData] = useState({
    pitchDeckUrl: '',
    whitepaperUrl: '',
    websiteUrl: '',
    twitterUrl: '',
    telegramUrl: '',
    discordUrl: '',
    emailUrl: '',
    otherUrlOne: '',
    otherUrlTwo: '',
  });
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const queryClient = useQueryClient();

  const onChangeData = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const removeText = (name) => {
    const newData = { ...data };
    newData[name] = '';
    setData(newData);
  };

  const onConfirm = async () => {
    const arr = [];
    arr[0] = data.pitchDeckUrl;
    arr[1] = data.whitepaperUrl;
    arr[2] = data.websiteUrl;
    arr[3] = data.twitterUrl;
    arr[4] = data.telegramUrl;
    arr[5] = data.discordUrl;
    arr[6] = data.emailUrl;
    arr[7] = data.otherUrlOne;
    arr[8] = data.otherUrlTwo;

    try {
      await updateDeal(deal.id, { projectLinks: arr });

      queryClient.invalidateQueries(['admin-deal', deal.id]);

      onClose();
      showSuccessNotification(`Updated successfully`);
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  useEffect(() => {
    if (deal?.projectLinks) {
      setData({
        ...data,
        pitchDeckUrl: deal.projectLinks[0] ? deal.projectLinks[0] : '',
        whitepaperUrl: deal.projectLinks[1] ? deal.projectLinks[1] : '',
        websiteUrl: deal.projectLinks[2] ? deal.projectLinks[2] : '',
        twitterUrl: deal.projectLinks[3] ? deal.projectLinks[3] : '',
        telegramUrl: deal.projectLinks[4] ? deal.projectLinks[4] : '',
        discordUrl: deal.projectLinks[5] ? deal.projectLinks[5] : '',
        emailUrl: deal.projectLinks[6] ? deal.projectLinks[6] : '',
        otherUrlOne: deal.projectLinks[7] ? deal.projectLinks[7] : '',
        otherUrlTwo: deal.projectLinks[8] ? deal.projectLinks[8] : '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open} className="deal-info-modal projects-modal" onClose={onClose}>
      <div className="modal-header">
        <div className="header-title">
          <SvgIcon name="iconDealsModalEdit" />
          <h3>Project Links</h3>
        </div>
        <div className="header-actions">
          <RoundedButton type="transparent" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" onClick={onConfirm}>
            <div className="d-flex">Publish</div>
          </RoundedButton>
        </div>
      </div>
      <form className="modal-body" id="projects-form" onSubmit={onConfirm}>
        <div className="col pitch-deck-col">
          <div className="input-holder">
            <div className="outer-icon-holder">
              <SvgIcon name="iconDealPitchDeck" />
            </div>
            <div className="input-holder-inner">
              <div className="flex-label">
                <label>Pitch Deck URL</label>
              </div>
              <div className="input">
                <input name="pitchDeckUrl" value={data.pitchDeckUrl} onChange={onChangeData} />
                <div className="icon-holder" onClick={() => removeText('pitchDeckUrl')}>
                  <SvgIcon name="iconDeleteX" />
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className="col">
          <div className="input-holder">
            <div className="outer-icon-holder">
              <SvgIcon name="iconDealWhitepaper" />
            </div>
            <div className="input-holder-inner">
              <div className="flex-label">
                <label>Whitepaper URL</label>
              </div>
              <div className="input">
                <input name="whitepaperUrl" value={data.whitepaperUrl} onChange={onChangeData} />
                <div className="icon-holder" onClick={() => removeText('whitepaperUrl')}>
                  <SvgIcon name="iconDeleteX" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="input-holder">
            <div className="outer-icon-holder">
              <SvgIcon name="iconDealWebsite" />
            </div>
            <div className="input-holder-inner">
              <div className="flex-label">
                <label>Website URL</label>
              </div>
              <div className="input">
                <input name="websiteUrl" value={data.websiteUrl} onChange={onChangeData} />
                <div className="icon-holder" onClick={() => removeText('websiteUrl')}>
                  <SvgIcon name="iconDeleteX" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="input-holder">
            <div className="outer-icon-holder">
              <SvgIcon name="iconDealTwitter" />
            </div>
            <div className="input-holder-inner">
              <div className="flex-label">
                <label>Twitter URL</label>
              </div>
              <div className="input">
                <input name="twitterUrl" value={data.twitterUrl} onChange={onChangeData} />
                <div className="icon-holder" onClick={() => removeText('twitterUrl')}>
                  <SvgIcon name="iconDeleteX" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="input-holder">
            <div className="outer-icon-holder">
              <SvgIcon name="iconDealTelegram" />
            </div>
            <div className="input-holder-inner">
              <div className="flex-label">
                <label>Telegram URL</label>
              </div>
              <div className="input">
                <input name="telegramUrl" value={data.telegramUrl} onChange={onChangeData} />
                <div className="icon-holder" onClick={() => removeText('telegramUrl')}>
                  <SvgIcon name="iconDeleteX" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="input-holder">
            <div className="outer-icon-holder">
              <SvgIcon name="iconDealDiscord" />
            </div>
            <div className="input-holder-inner">
              <div className="flex-label">
                <label>Discord URL</label>
              </div>
              <div className="input">
                <input name="discordUrl" value={data.discordUrl} onChange={onChangeData} />
                <div className="icon-holder" onClick={() => removeText('discordUrl')}>
                  <SvgIcon name="iconDeleteX" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="input-holder">
            <div className="outer-icon-holder">
              <SvgIcon name="iconProjectLinksBlog" />
            </div>
            <div className="input-holder-inner">
              <div className="flex-label">
                <label>Blog</label>
              </div>
              <div className="input">
                <input name="emailUrl" value={data.emailUrl} onChange={onChangeData} />
                <div className="icon-holder" onClick={() => removeText('emailUrl')}>
                  <SvgIcon name="iconDeleteX" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="input-holder">
            <div className="outer-icon-holder">
              <SvgIcon name="iconProjectLinksLink" />
            </div>
            <div className="input-holder-inner">
              <div className="flex-label">
                <label>Other URL</label>
              </div>
              <div className="input">
                <input name="otherUrlOne" value={data.otherUrlOne} onChange={onChangeData} />
                <div className="icon-holder" onClick={() => removeText('otherUrlOne')}>
                  <SvgIcon name="iconDeleteX" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="input-holder">
            <div className="outer-icon-holder">
              <SvgIcon name="iconProjectLinksLink" />
            </div>
            <div className="input-holder-inner">
              <div className="flex-label">
                <label>Other URL</label>
              </div>
              <div className="input">
                <input name="otherUrlTwo" value={data.otherUrlTwo} onChange={onChangeData} />
                <div className="icon-holder" onClick={() => removeText('otherUrlTwo')}>
                  <SvgIcon name="iconDeleteX" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default ProjectsModal;
