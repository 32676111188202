import React, { useEffect } from 'react';
import NumberInput from 'components/input/number-input';
import PoolStatus from 'contracts/poolManager/constants';

import DeployContractAction from '../actions/DeployContract';
import { useDeployContractContext } from '../context';
import { validatePhasesCaps, validatePoolConfigField } from './validators';
import UpdatePoolAction from '../actions/UpdatePool';

const PoolConfigParams = ['minContribution', 'maxContribution', 'dealSize'];

const DeployContractHeader = () => {
  const { deal, setPoolConfig, poolConfig, isLoading, errors, setErrors, hasErrors, phases } =
    useDeployContractContext();

  useEffect(() => {
    const updatedConfig = PoolConfigParams.reduce((acc, key) => {
      if (deal[key]) {
        acc[key] = deal[key];
      }
      return acc;
    }, {});

    setPoolConfig((prevDetails) => ({
      ...prevDetails,
      ...updatedConfig,
      maxContribution: +deal?.maxContribution || '',
    }));
  }, [deal, setPoolConfig]);

  const handleChange = (key, value) => {
    const newPoolConfig = { ...poolConfig, [key]: value };

    validatePoolConfigField(key, value, {
      setErrors,
      poolConfig: newPoolConfig,
    });

    validatePhasesCaps(phases, { poolConfig: newPoolConfig, errors, setErrors });

    setPoolConfig(newPoolConfig);
  };

  const onValidate = () => {
    const errors = PoolConfigParams.map((key) =>
      validatePoolConfigField(key, poolConfig[key], {
        setErrors,
        poolConfig,
      })
    );

    return { errors, isFormValid: !errors.some((error) => !!error) };
  };

  return (
    <div className="general">
      <div className="general__inputs">
        <NumberInput
          label="Min Contribution"
          value={poolConfig.minContribution}
          onChange={(e) => handleChange('minContribution', e.target.value)}
          decimalNumber="2"
          error={errors.minContribution}
          disabled={isLoading}
        />
        <NumberInput
          label="Max Contribution"
          subLabel="(optional)"
          value={poolConfig.maxContribution}
          onChange={(e) => handleChange('maxContribution', e.target.value)}
          decimalNumber="2"
          error={errors.maxContribution}
          disabled={isLoading}
        />
        <NumberInput
          label="Deal Size"
          value={poolConfig.dealSize}
          onChange={(e) => handleChange('dealSize', e.target.value)}
          decimalNumber="2"
          error={errors.dealSize}
          disabled={isLoading}
        />
      </div>
      <div className="general__actions">
        <div className="general__actions-deploy">
          {deal.statusId === PoolStatus.Active && phases.length > 0 ? (
            <UpdatePoolAction disabled={isLoading} onValidate={onValidate} />
          ) : (
            <DeployContractAction disabled={hasErrors || isLoading} onValidate={onValidate} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DeployContractHeader;
