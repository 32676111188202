import RoundedButton from 'components/button/rounded-button';
import { isAddress, getAddress } from 'ethers/lib/utils';
import React from 'react';
import CSVReader from 'react-csv-reader';

const ImportWhitelistCSV = ({ inputId, onImport }) => {
  const handleFileLoaded = (data) => {
    const whitelist = data.reduce((acc, [address, personalCap]) => {
      if (isAddress(address)) {
        acc.push({ address: getAddress(address), personalCap: personalCap || 0 });
      }

      return acc;
    }, []);

    onImport(whitelist);
  };

  const handleButtonClick = () => {
    document.getElementById(inputId).click();
  };

  return (
    <div className="import-whitelist-csv">
      <RoundedButton type="secondary" onClick={handleButtonClick}>
        Import from CSV
      </RoundedButton>
      <CSVReader
        onFileLoaded={handleFileLoaded}
        inputId={inputId}
        inputStyle={{ display: 'none' }}
      />
    </div>
  );
};

export default ImportWhitelistCSV;
