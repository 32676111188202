import React from 'react';
import NumberInput from 'components/input/number-input';
import Checkbox from 'components/checkbox';

const RoleDealManagement = ({
  role,
  setRole,
  isDealManagementSelected,
  setIsDealManagementSelected,
}) => {
  const handleDealManagement = (isChecked) => {
    setIsDealManagementSelected(isChecked);

    setRole({ ...role, maxCloseAmount: isChecked ? role.maxCloseAmount : '' });
  };

  return (
    <div className="deal-management-container">
      <div className="deal-management-checkbox role-info-container checkbox-option-container">
        <Checkbox
          checked={isDealManagementSelected}
          onChange={(e) => handleDealManagement(e.target.checked)}
        />
        <span>Deal Management</span>
      </div>
      <div className="role-info">
        <NumberInput
          label={isDealManagementSelected ? 'Amount' : ''}
          name="maxCloseAmount"
          value={role.maxCloseAmount}
          onChange={(e) => {
            setRole({ ...role, maxCloseAmount: e.target.value });
          }}
          decimalNumber={2}
          disabled={!isDealManagementSelected}
        />
      </div>
    </div>
  );
};

export default RoleDealManagement;
