import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogContent, Divider } from '@material-ui/core';
import SvgIcon from 'components/svgIcon';
import RoundedButton from 'components/button/rounded-button';
import { deleteWhitelist, getWhitelists } from 'services/apiService';
import { useNotifications } from 'hooks';
import { useHistory } from 'react-router-dom';
import Checkbox from 'components/checkbox';
import IconButton from 'components/button/icon-button';
import WhitelistDeleteDialog from './WhitelistDeleteDialog';

const WhitelistPickerModal = ({ open, onClose, onSave, whitelistPledges }) => {
  const [whitelists, setWhitelists] = useState([]);
  const [whitlistToDelete, setWhitelistToDelete] = useState(null);
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const history = useHistory();

  const updateWhitelists = useCallback(
    (whitelists, mapPledges = false) => {
      const newWhitelists = [];

      if (whitelistPledges) {
        newWhitelists.push(whitelistPledges);
      }

      if (mapPledges) {
        const mappedWhitelists = whitelists.map((whitelist) => ({
          ...whitelist,
          checked: false,
        }));

        newWhitelists.push(...mappedWhitelists);
      } else {
        newWhitelists.push(...whitelists);
      }

      setWhitelists(newWhitelists);
    },
    [whitelistPledges]
  );

  const fetchWhitelists = () => getWhitelists().then(updateWhitelists);

  useEffect(() => {
    if (whitelists?.length > 0) {
      return;
    }

    getWhitelists().then((res) => updateWhitelists(res, true));
  }, [updateWhitelists, whitelists]);

  const onDelete = async () => {
    if (!whitlistToDelete) return;
    try {
      await deleteWhitelist(whitlistToDelete.id);
      fetchWhitelists();
      setWhitelistToDelete(null);
      showSuccessNotification('Whitelist successfully deleted');
    } catch {
      showErrorNotification('Something went wrong, please try again!');
    }
  };

  const handleSave = () => {
    onSave(
      whitelists
        .map((whitelist) =>
          whitelist.checked ? whitelist.addresses.map(({ address }) => address) : []
        )
        .flat()
    );
    setWhitelists((prev) => prev.map((whitelist) => ({ ...whitelist, checked: false })));
    onClose();
  };

  return (
    <>
      <WhitelistDeleteDialog
        whitelist={whitlistToDelete}
        setWhitelist={setWhitelistToDelete}
        onDelete={onDelete}
      />
      <Dialog
        open={open}
        classes={{ paper: 'whitelists-modal whitelists-container whitelist-modal__picker' }}
        onClose={onClose}
      >
        <div className="whitelists-modal__header">
          <h2>
            <SvgIcon name="iconDealsModalEdit" />
            <span>Whitelists</span>
          </h2>
          <div>
            <RoundedButton type="transparent" onClick={onClose}>
              Cancel
            </RoundedButton>
            <RoundedButton type="primary" onClick={handleSave}>
              Save
            </RoundedButton>
          </div>
        </div>
        <Divider />
        <DialogContent>
          <div className="deploy-contract__whitelist-picker">
            <ul>
              {whitelists.map(({ name, id, checked }) => (
                <li key={id}>
                  <div>
                    <Checkbox
                      checked={checked}
                      onChange={(e) =>
                        setWhitelists((prev) =>
                          prev.map((whitelist) =>
                            whitelist.id === id
                              ? { ...whitelist, checked: e.target.checked }
                              : whitelist
                          )
                        )
                      }
                    />{' '}
                    <span>{name}</span>
                  </div>
                  <div>
                    <IconButton onClick={() => history.push('/whitelists')} icon="iconDealsEdit" />
                    <IconButton
                      icon="iconErase"
                      onClick={() => setWhitelistToDelete({ name, id })}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WhitelistPickerModal;
