import React from 'react';
import moment from 'moment';
import { renderSafeValue } from 'utils';
import RiskFactor from 'components/risk-factor';
import InfoContainer from '../../../../../components/InfoContainer';

const PersonalInfo = ({ user }) => {
  if (!user) return null;

  const riskFactorLabel = ({ riskFactor }) => {
    switch (riskFactor) {
      case 1:
        return 'Low';
      case 2:
        return 'Medium';
      case 3:
        return 'High';
      default:
        return null;
    }
  };

  const userInfo = [
    { label: 'Risk factor', value: <RiskFactor riskFactor={riskFactorLabel(user)} /> },
    {
      label: 'Registration Date',
      value: user.createdAt ? moment(user.createdAt).format('DD MMM YYYY') : null,
    },
    {
      label: 'Last Login Date',
      value: user.createdAt ? moment(user.lastLogin).format('DD MMM YYYY') : null,
    },
    { label: 'Email', value: user.userEmail?.email },
    {
      label: 'Email verified',
      value: user.userEmail?.isVerified ? 'Yes' : 'No',
    },
    {
      label: 'Email verification date',
      value: user.userEmail?.isVerified
        ? moment(user.userEmail.verifiedAt).format('DD MMM YYYY')
        : null,
    },
    { label: 'Discord', value: user.discord },
    { label: 'Politically Exposed', value: user.pepInfo?.isPep ? 'Yes' : 'No' },
    { label: 'Primary Business Intentions', value: user.primaryIntentions },
    { label: 'Source of Funds', value: user.sourceOfFunds },
  ];

  return (
    <InfoContainer title="USER">
      <ul className="item-list">
        {userInfo.map((info) => (
          <li key={info.label} className="item">
            <p className="label">{info.label}</p>
            <p className="value">{renderSafeValue(info.value)}</p>
          </li>
        ))}
      </ul>
    </InfoContainer>
  );
};

export default PersonalInfo;
