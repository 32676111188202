import React from 'react';
import SvgIcon from 'components/svgIcon';
import AdminModal from 'features/deals/AdminModal';
import useAdminDealData from './hooks/useAdminDealData';

const AdminTab = ({ deal, adminOverviewModalOpen, toggleAdminOverviewModal }) => {
  const { data, isLoading } = useAdminDealData(deal.id);

  const dealmakerInfo = [
    {
      key: 'Name',
      value: data?.dealmakerName,
    },
    {
      key: 'Role',
      value: data?.dealmakerRoleName,
    },
    {
      key: 'Amount Limit',
      value: data?.dealmakerCloseAmountLimit,
    },
    {
      key: 'Wallet',
      value: data?.dealmakerWallet,
    },
    {
      key: 'Current role',
      value: data?.dealmakerCurrentRoleName,
    },
  ];

  return (
    <div className="content">
      <div className="content-row">
        <div className="col col-4 overview-col">
          {adminOverviewModalOpen && (
            <AdminModal
              open={adminOverviewModalOpen}
              onClose={toggleAdminOverviewModal}
              deal={deal}
              dealStatusInfo={data.dealStatusInfo?.name}
            />
          )}
          <div className="edit-button" onClick={toggleAdminOverviewModal}>
            <SvgIcon name="iconDealsEdit" />
          </div>
          <h3 className="col-title">OVERVIEW</h3>
          <div className="metrics-holder">
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>Status info:</p>
              </div>
              <div className="box-col col-right">
                {isLoading ? <p>Loading...</p> : <p>{data.dealStatusInfo?.name || '-'}</p>}
              </div>
            </div>
            <div className="metrics-box">
              <div className="box-col col-left">
                <p>CoinGecko API:</p>
              </div>
              <div className="box-col col-right">
                <p>{deal.coingeckoApiId || '-'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-8">
          <h3 className="col-title">DEALMAKER INFO</h3>
          <div className="metrics-holder">
            {dealmakerInfo.map((item) => (
              <div className="metrics-box" key={item.key}>
                <div className="box-col col-left">
                  <p>{item.key}:</p>
                </div>
                <div className="box-col col-right">
                  <p>{item.value || '-'}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTab;
