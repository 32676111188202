import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import ContractRoleTableRow from './ContractRoleTableRow';

const ContractRolesTable = ({ contractRoles }) => {
  return (
    <>
      <h2 className="contract-table-title">Contract Roles:</h2>
      <TableContainer classes={{ root: 'users-table' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ROLE NAME</TableCell>
              <TableCell>MAX CLOSE AMOUNT</TableCell>
              <TableCell>CREATED AT</TableCell>
              <TableCell>POOL MANAGER ADDRESS</TableCell>
              <TableCell>TX HASH</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contractRoles.map((data) => (
              <ContractRoleTableRow key={data.id} data={data} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ContractRolesTable;
