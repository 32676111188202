import React, { useMemo } from 'react';
import RoundedButton from 'components/button/rounded-button';

import { useNotifications } from 'hooks';
import { useQueryClient } from 'react-query';
import useCreatePoolPhases from 'contracts/poolManager/hooks/useCreatePoolPhases';
import useWithErrorNotifier from 'hooks/useWithErrorNotifier';
import { useDeployContractContext } from '../context';
import usePhaseMerkleRoot from '../../hooks/usePhaseMerkleRoot';
import { preparePhasesForDeploy } from '../helpers';

function CreatePhasesAction({ phase }) {
  const [showSuccessNotification] = useNotifications();

  const { deal, poolConfig } = useDeployContractContext();

  const queryClient = useQueryClient();

  const { createPoolPhases, isLoading: isCreatingPhase } = useCreatePoolPhases(deal.address, {
    onSuccess: () => {
      queryClient.invalidateQueries(['deal', deal.id]);
      queryClient.invalidateQueries(['admin-deal', deal.id]);
      queryClient.invalidateQueries(['deal', deal.id, 'pledge']);

      showSuccessNotification('Phase created successfully');
    },
  });

  const onSuccess = useWithErrorNotifier((result) => {
    const phases = preparePhasesForDeploy(result.phases);

    if (!phases) {
      return;
    }

    createPoolPhases({
      ...phases,
    });
  });

  const { insertMerkleRoots } = usePhaseMerkleRoot(deal.id, {
    onSuccess,
  });

  const isPhaseValid = useMemo(() => {
    const hasValidDates = phase.startDate < phase.endDate;
    const hasName = phase.phaseName;

    return hasValidDates && hasName;
  }, [phase]);

  const onCreate = (e) => {
    e.stopPropagation();
    insertMerkleRoots({ phases: [phase], poolConfig });
  };

  return (
    <RoundedButton onClick={onCreate} disabled={isCreatingPhase || !isPhaseValid} type="secondary">
      Create
    </RoundedButton>
  );
}

export default CreatePhasesAction;
