import React, { useCallback } from 'react';
import IconButton from 'components/button/icon-button';
import PledgeStatus from 'contracts/pledgeVault/constants';
import { useDeployContractContext } from '../context';
import UpdatePhaseAction from '../actions/UpdatePhase';
import CreatePhasesAction from '../actions/CreatePhases';

const PhaseActions = ({ index, phase, isNew }) => {
  const { pledgePool, setPhases } = useDeployContractContext();

  const renderActions = useCallback(() => {
    if (isNew) {
      return <CreatePhasesAction phase={phase} />;
    }

    return <UpdatePhaseAction phase={phase} />;
  }, [isNew, phase]);

  return (
    <>
      {pledgePool?.status === PledgeStatus.Finalized ? renderActions() : null}
      {isNew ? (
        <div className="delete-icon">
          <IconButton
            icon="iconDeleteX"
            onClick={() => {
              setPhases((prev) => prev.filter((_, i) => i !== index));
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default PhaseActions;
