import { useQuery } from 'react-query';
import { getPledge } from 'services/apiService';

const useGetPledge = (dealId, isActive, options = {}) => {
  return useQuery(['deal', dealId, 'pledge'], () => getPledge(dealId), {
    enabled: isActive,
    placeholderData: [],
    ...options,
  });
};

export default { useGetPledge };
