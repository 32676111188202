import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { DEFAULT_CHAIN_ID } from 'constants/config';
import TxHashLink from 'components/TxHashLink';

const ContractRoleTableRow = ({ data }) => {
  return (
    <TableRow>
      <TableCell>{data.roleName}</TableCell>
      <TableCell>
        <NumberFormat
          value={Number(data.maxCloseAmount / 1000000).toFixed(0)}
          thousandSeparator
          displayType="text"
          prefix="$"
        />
      </TableCell>
      <TableCell>{moment(data.createdAt).format('DD-MMM-YYYY')}</TableCell>
      <TableCell>{data.poolManagerAddress}</TableCell>
      <TableCell>
        <TxHashLink chainId={DEFAULT_CHAIN_ID} hash={data.txHash} />
      </TableCell>
    </TableRow>
  );
};

export default ContractRoleTableRow;
