import React, { useEffect, useState } from 'react';
import SvgIcon from 'components/svgIcon';
import WhitelistPickerModal from 'features/whitelists/WhitelistPickerModal';
import remote from 'features/deals/AdminDealsTable/remote';
import { useDeployContractContext } from '../context';
import { InitialPhaseState } from '../constants';

function DeployContractFooter() {
  const { deal, whitelistPickerData, setWhitelistPickerData, handlePhaseChange, setPhases } =
    useDeployContractContext();

  const [whitelistPledges, setWhitelistPledges] = useState();

  const { data: pledges } = remote.useGetPledge(deal.id, true);

  useEffect(() => {
    if (!pledges || whitelistPledges) return;

    const pledgeAddresses = pledges.map((pledge) => ({ address: pledge.wallet }));

    if (pledgeAddresses.length === 0) return;

    setWhitelistPledges({
      name: 'Pledged Wallets',
      id: 'pledges',
      addresses: pledgeAddresses,
      checked: false,
    });
  }, [pledges, whitelistPledges]);

  return (
    <>
      <WhitelistPickerModal
        open={!!whitelistPickerData}
        whitelistPledges={whitelistPledges}
        onClose={() => setWhitelistPickerData(null)}
        onSave={(newAddresses) =>
          handlePhaseChange(
            'whitelist',
            [
              ...whitelistPickerData.whitelist,
              ...newAddresses.map((address) => ({ address, personalCap: 0 })),
            ],
            whitelistPickerData.i
          )
        }
      />
      <div
        className="add-phase"
        onClick={() =>
          setPhases((prev) => [...prev, { ...InitialPhaseState, index: prev.length + 1 }])
        }
      >
        <SvgIcon name="plus" />
      </div>
    </>
  );
}

export default DeployContractFooter;
