import React from 'react';
import RoundedButton from 'components/button/rounded-button';
import './index.scss';
import remote from '../../remote';

const DownloadReportButton = ({ type, address }) => {
  const handleDownloadReport = async () => {
    try {
      const response = await remote.getRiskReportFile(type, address);

      const url = window.URL.createObjectURL(new Blob([response]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `${type}_report.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the report:', error);
    }
  };

  return (
    <RoundedButton className="download-button" type="secondary" onClick={handleDownloadReport}>
      Download
    </RoundedButton>
  );
};

export default DownloadReportButton;
