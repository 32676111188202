import { useQuery } from 'react-query';
import { getAllRoles } from 'services/apiService';

function groupRoles(roles) {
  const customRoles = roles.filter((role) => role.isCustom);

  const contractRoles = roles
    .filter((role) => role.poolManagerInfo)
    .map((role) => ({
      ...role.poolManagerInfo,
      roleName: role.name,
    }));

  return { customRoles, contractRoles };
}

const useRoles = () => {
  const { data, isFetching } = useQuery(['roles'], async () => {
    const all = await getAllRoles();

    const { customRoles, contractRoles } = groupRoles(all);

    return { all, customRoles, contractRoles };
  });

  return {
    roles: data,
    isLoading: isFetching,
  };
};

export default useRoles;
