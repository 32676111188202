import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import RoundedAvatar from 'components/avatar/rounded-avatar';
import { removeAnimateDeal, setOldDeal } from 'store/actions';
import './index.scss';
import RoundedButton from 'components/button/rounded-button';
import IconButton from 'components/button/icon-button';
import ClaimOldDealModal from 'features/deals/ClaimOldDealModal';
import ClaimDealChecModal from 'features/deals/ClaimCheckModal';
import { archiveOldDeal, unarchiveOldDeal } from 'services/apiService';
import { useNotifications } from 'hooks';
import Tooltip from 'components/tooltip';
import { Box } from '@material-ui/core';

const OldDealRow = ({ deal }) => {
  const globalReducer = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [claimModalOpened, setClaimModalOpened] = useState(false);
  const [claimCheckModalOpened, setClaimCheckModalOpened] = useState(false);
  const [showSuccessNotification, showErrorNotification] = useNotifications();

  const { animateDeals } = globalReducer;

  const onManage = (e) => {
    const disabledTags = ['input', 'button'];
    if (disabledTags.includes(e.target.tagName.toLowerCase())) return;
    toggleDrop();
  };

  const toggleDrop = () => {
    setShowDropdown(!showDropdown);
  };

  const onToggleClaimModal = () => {
    setClaimModalOpened(!claimModalOpened);
  };

  const onToggleClaimCheckModal = () => {
    setClaimCheckModalOpened(!claimCheckModalOpened);
  };

  const getAnimateClass = (val) => {
    if (
      animateDeals &&
      animateDeals.fields &&
      deal.address === animateDeals.address &&
      (animateDeals?.fields.includes(val) || animateDeals?.fields.includes('allFields'))
    ) {
      return 'animate';
    }
    return val;
  };

  useEffect(() => {
    if (animateDeals && animateDeals.fields && deal.address === animateDeals.address) {
      setTimeout(() => {
        dispatch(removeAnimateDeal());
      }, 3000);
    }
  }, [animateDeals, deal, dispatch]);

  const handleArchive = async () => {
    try {
      await archiveOldDeal(deal.id);
      deal.isArchived = true;
      dispatch(setOldDeal(deal));
      showSuccessNotification('Legacy Deal archived successfully');
    } catch {
      showErrorNotification('Something went wrong');
    }
  };

  const handleUnarchive = async () => {
    try {
      await unarchiveOldDeal(deal.id);
      deal.isArchived = false;
      dispatch(setOldDeal(deal));
      showSuccessNotification('Legacy Deal unarchived successfully');
    } catch {
      showErrorNotification('Something went wrong');
    }
  };

  return (
    <>
      {claimModalOpened && (
        <ClaimOldDealModal open={claimModalOpened} oldDeal={deal} onClose={onToggleClaimModal} />
      )}
      {claimCheckModalOpened && (
        <ClaimDealChecModal
          open={claimCheckModalOpened}
          deal={deal}
          onClose={onToggleClaimCheckModal}
        />
      )}
      <div className="deal-holder deal-holder-old-deal d-flex full-width">
        <div className="deal-row-top" onClick={onManage}>
          <div
            className={`deal__field deal__field-avatar vertical-center ${getAnimateClass(
              'imageUrl'
            )}`}
          >
            <RoundedAvatar src={deal.imageUrl} />
          </div>
          <div
            className={`deal__field deal__field-name vertical-center ${getAnimateClass('name')}`}
          >
            <div>
              <span>{deal.name}</span>
            </div>
          </div>
          <div
            className={`deal__field deal__field-model vertical-center ${getAnimateClass(
              'allocationModel'
            )}`}
          >
            <span>{deal.allocationModel}</span>
          </div>
          <div
            className={`deal__field deal__field-action vertical-center ${getAnimateClass(
              'minContribution'
            )}`}
          >
            <RoundedButton
              type="secondary"
              disabled={!deal.claimers.some((c) => Number(c.claimable) > 0)}
              onClick={() => onToggleClaimModal()}
            >
              Claim
            </RoundedButton>
          </div>
          <div className="deal__field deal__field-claimed old-deal vertical-center">
            <Box display="flex" width={60} justifyContent="space-between">
              <span>
                {deal.claimers.some(({ claims }) => !!claims.length) ? (
                  <IconButton icon="iconTokensClaimed" onClick={onToggleClaimCheckModal} />
                ) : (
                  <IconButton icon="iconTokensClaimedDisabled" />
                )}
              </span>
              <Tooltip title={deal.isArchived ? 'Unarchive' : 'Archive'}>
                <div>
                  {deal.isArchived ? (
                    <IconButton icon="iconVisibility" onClick={handleUnarchive} />
                  ) : (
                    <IconButton icon="iconVisibilityOffGray" onClick={handleArchive} />
                  )}
                </div>
              </Tooltip>
            </Box>
          </div>
        </div>

        <div className={`deal-info-mobile${showDropdown ? ' show' : ''}`}>
          <div className="deal-info-mobile-box">
            <p>Raise Model</p>
            <h3>{deal.allocationModel}</h3>
          </div>
          <div className="deal-info-mobile-box">
            <p>My Contribution</p>
            <h3>N/A</h3>
          </div>
        </div>
      </div>
    </>
  );
};

OldDealRow.propTypes = {
  deal: PropTypes.shape(),
};

OldDealRow.defaultProps = {
  deal: {},
};

export default React.memo(OldDealRow);
