import React, { useState, useEffect } from 'react';
import { updateDeal } from 'services/apiService';
import Dialog from '@material-ui/core/Dialog';
import RoundedButton from 'components/button/rounded-button';
import SvgIcon from 'components/svgIcon';
import { useNotifications } from 'hooks';
import { useQueryClient } from 'react-query';

const HighlightsModal = ({ open, onClose, deal }) => {
  const [data, setData] = useState();
  const [showSuccessNotification, showErrorNotification] = useNotifications();
  const [errorMessage, setErrorMessage] = useState('');

  const onChangeData = (e) => {
    const { value } = e.target;
    setData(value);
  };

  const queryClient = useQueryClient();

  const onConfirm = async () => {
    const asteriskCount = (data.match(/\*/g) || []).length;
    const degreeCount = (data.match(/°/g) || []).length;
    if (asteriskCount % 2 !== 0) {
      setErrorMessage('Must have an even number of asterisks');
      return;
    }
    if (degreeCount % 2 !== 0) {
      setErrorMessage('Must have an even number of degree signs');
      return;
    }
    setErrorMessage('');
    try {
      await updateDeal(deal.id, { highlights: data });

      queryClient.invalidateQueries(['admin-deal', deal.id]);

      onClose();
      showSuccessNotification(`Updated successfully`);
    } catch (err) {
      console.error(err);
      showErrorNotification('Something went wrong.');
    }
  };

  useEffect(() => {
    const highlights = deal?.highlights ? deal.highlights : '';
    setData(highlights);
    // if (highlights[0]) highlights[0] = `* ${highlights[0].trim()}\n`;
    // setData(highlights.toString().split(',').join('*'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open} className="deal-info-modal highlights-modal" onClose={onClose}>
      <div className="modal-header">
        <div className="header-title">
          <SvgIcon name="iconDealsModalEdit" />
          <h3>Highlights</h3>
        </div>
        <div className="header-actions">
          <RoundedButton type="transparent" onClick={onClose}>
            Cancel
          </RoundedButton>
          <RoundedButton type="primary" onClick={onConfirm}>
            <div className="d-flex">Publish</div>
          </RoundedButton>
        </div>
      </div>
      <form className="modal-body" id="highlights-form">
        <div className="input-holder">
          <label>Text Edit</label>
          <textarea
            name="text"
            cols="30"
            value={data}
            className={`${errorMessage ? 'error' : ''}`}
            rows="6"
            onChange={onChangeData}
          />
          {errorMessage && <span className="input-error-message">{errorMessage}</span>}
        </div>
      </form>
    </Dialog>
  );
};

export default HighlightsModal;
