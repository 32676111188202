import React, { useEffect, useState, useMemo } from 'react';
import Whitelist from 'features/deals/AdminDealsTable/Whitelist';
import SvgIcon from 'components/svgIcon';
import BdtLevelCapInput from 'features/deals/AdminDealsTable/BdtLevelCapInput';
import NumberInput from 'components/input/number-input';
import Checkbox from 'components/checkbox';
import ImportWhitelistCSV from 'features/deals/AdminDealsTable/ImportWhitelistCSV';
import InfoTooltip from 'components/tooltip/custom/InfoTooltip';
import { useDeployContractContext } from '../context';

const PhaseDetails = ({ phase, index }) => {
  const { setWhitelistPickerData, handlePhaseChange, errors } = useDeployContractContext();

  const [isWhitelistEnabled, setIsWhitelistEnabled] = useState(false);

  const shouldDisplayWhitelist = () => {
    return (
      phase.minAccessLevel.toString() === '4' ||
      (Array.isArray(phase.whitelist) && phase.whitelist.length > 0) ||
      phase.minViewLevel.toString() === '4' ||
      (phase.model === 'WalletCap' && isWhitelistEnabled)
    );
  };

  useEffect(() => {
    if (!phase.whitelist) {
      return;
    }

    setIsWhitelistEnabled(true);
  }, [phase.whitelist]);

  const invalidWhitelistAddresses = useMemo(() => {
    if (!errors?.[`${index}-whitelist`]) {
      return [];
    }

    return errors?.[`${index}-whitelist`]?.map(({ address }) => address);
  }, [errors, index]);

  return (
    <div>
      {phase.model === 'WalletCap' ? (
        <>
          <BdtLevelCapInput
            phaseLevels={phase.levels}
            setPhaseLevels={(updatedLevels) => handlePhaseChange('levels', updatedLevels, index)}
            errors={errors[`${index}-levels`]}
          />
        </>
      ) : null}
      <div className="cap-container-wrapper">
        <div className="cap-container">
          <NumberInput
            label="Cap"
            subLabel="(optional)"
            value={phase.cap}
            onChange={(e) => handlePhaseChange('cap', e.target.value, index)}
            decimalNumber="2"
            error={errors[`${index}-cap`]}
          />
        </div>
        <div className="cap-container">
          <NumberInput
            label="Nft Price"
            value={phase.nftPrice}
            onChange={(e) => handlePhaseChange('nftPrice', e.target.value, index)}
            decimalNumber="2"
            error={errors[`${index}-nftPrice`]}
            subLabel={
              <>
                <span>(optional)</span>
                <InfoTooltip
                  title="Add the nft price to ensure contributions are multiples of this value."
                  placement="right"
                />
              </>
            }
          />
        </div>
      </div>
      {phase.model === 'WalletCap' && phase.minAccessLevel.toString() !== '4' ? (
        <div className="whitelist-checkbox">
          <Checkbox
            checked={isWhitelistEnabled}
            onChange={() => setIsWhitelistEnabled(!isWhitelistEnabled)}
          />
          <span>Whitelist</span>
        </div>
      ) : null}
      {shouldDisplayWhitelist() ? (
        <div className="deal-edit-modal__content whitelist-content">
          <div className="whitelist-header">
            <label>
              Whitelist
              <button
                type="button"
                onClick={() => setWhitelistPickerData({ whitelist: phase.whitelist, i: index })}
              >
                <SvgIcon name="iconDownloadPledge" width={16} height={16} />
              </button>
            </label>
            <ImportWhitelistCSV
              inputId={`csv-input-phase-${index}`}
              onImport={(newAddresses) => {
                handlePhaseChange('whitelist', [...phase.whitelist, ...newAddresses], index);
              }}
            />
          </div>
          <Whitelist
            showAmount={phase.model === 'WalletCap'}
            whitelistId={`${phase.dealId}-${index}`}
            whitelist={phase.whitelist}
            onChange={(updatedWhitelist) => handlePhaseChange('whitelist', updatedWhitelist, index)}
            onValidityChange={() => null}
            invalidAddresses={invalidWhitelistAddresses}
            error="Exceeds deal size"
          />
        </div>
      ) : null}
    </div>
  );
};

export default PhaseDetails;
