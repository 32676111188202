import React from 'react';
import PhaseItem from './PhaseItem';
import { useDeployContractContext } from '../context';

const PhaseList = () => {
  const { phases, deal } = useDeployContractContext();

  const createdPhaseIds = deal.phases.map(({ index }) => index);

  return (
    <div>
      <div className="header-column">
        <label>#</label>
        <label>Access Level</label>
        <label>Phase</label>
        <label>Model</label>
        <label>Start Time</label>
        <label>End Time</label>
        <label>View Level</label>
        <label>Action</label>
      </div>
      {phases.map((phase, i) => (
        <PhaseItem
          key={`${phase.dealId}-${i}`}
          index={i}
          phase={phase}
          isNew={!createdPhaseIds.includes(phase.index)}
        />
      ))}
    </div>
  );
};

export default PhaseList;
