const InitialPhaseState = {
  minAccessLevel: '0',
  minViewLevel: '0',
  model: 'ProRata',
  startDate: new Date(),
  endDate: new Date(),
  cap: 0,
  whitelist: [],
  levels: [0, 0, 0, 0, 0],
  expanded: false,
  nftPrice: 0,
  index: 1,
};

const AllocationModel = {
  Unlimited: '0',
  WalletCap: '1',
  ProRata: '2',
};

const LevelOptions = [
  { name: 'Level 0', value: '0' },
  { name: 'Level 1', value: '1' },
  { name: 'Level 2', value: '2' },
  { name: 'Level 3', value: '3' },
  { name: 'Whitelist', value: '4' },
];

const AllocationModelOptions = [
  { name: 'Unlimited', value: 'Unlimited' },
  { name: 'Wallet Cap', value: 'WalletCap', subtitle: 'per Address' },
  { name: 'Pro Rata', value: 'ProRata' },
];

export { InitialPhaseState, AllocationModel, LevelOptions, AllocationModelOptions };
